<!-- DIRTY DUPLICATE FROM SLASH VIEW (only temporary for permissions issues) -->
<template>
  <div class="flex">
    <div :class="'flex flex-1 flex-col print:max-w-[unset]'">
      <!-- PREVIEW -->
      <div v-html="'<style>' + css + '</style>'" v-for="(css, idx) in templateCss" :key="idx"></div>
      <!-- <div>{{ state }}</div> -->
      <div v-if="state === 'ready'" class="[font-size:--text_size]" :style="theme.variables">
        <div
          class="theme-all pdf isolate flex flex-wrap justify-center gap-8 p-8"
          :class="[theme.class, { blueprint: blueprint && tab !== 'theme' }]"
          :style="{ zoom }"
          :key="refreshKey"
        >
          <template v-for="(page, index) in template.nodes" :key="index">
            <builder
              data-builder
              class="pdf-page"
              :node="page"
              :data="dataReport"
              :layout="template"
              :store="store"
              :context="context"
              :path="[index]"
              :virtual="page.isVirtual"
              :isPreviewMode="true"
              @active="storeActive"
              @overflow="onOverflow"
            />
            <builder
              class="pdf-page"
              v-if="template.overflowPages?.[index]"
              :node="template.overflowPages?.[index]"
              :data="dataReport"
              :store="store"
              :layout="template"
              :context="context"
              :virtual="true"
              @active="storeActive"
              :path="[index]"
            ></builder>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pdf-block {
  min-height: 10px;
}
</style>

<script setup lang="ts">
import { ref, onMounted, watch, computed, Ref, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import templateService from '@100-m/hauru/src/services/TemplateService'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import stylesheetService from '@100-m/hauru/src/services/StylesheetService'
import useStore from '../../builder/composables/store'
import useBuilderContext, { addDataReportTranslations } from '../../builder/composables/builderContext'
import type { TemplateLayout } from '../../builder/builder'
import { getTemplateCss } from '../../builder/composables/theme'
import { migrateLayout } from '../../builder/lib/migrate'
import useLayout from '../../builder/composables/layout'

const stylesheets = ref<string[]>([])
const router = useRouter()
const route = useRoute()
function updateQuery(query: Record<string, string>) {
  router.push({ query: { ...route.query, ...query } })
}
Object.equal = (a, b) => {
  if (a === b) return true
  const ta = Object.prototype.toString.call(a)
  if (ta !== Object.prototype.toString.call(b)) return false
  if (!['[object Object]', '[object Array]'].includes(ta)) return a.toString() === b.toString()
  if (Object.keys(a).length !== Object.keys(b).length) return false
  return Object.keys(a).every(k => Object.equal(a[k], b[k]))
}
Object.traverse = (obj, fnLeaf, fnNode, path = [], root) => {
  if (!obj) return
  if (obj instanceof Object) {
    fnNode(obj, path, root)
    return Object.keys(obj).forEach((k, i) => Object.traverse(obj[k], fnLeaf, fnNode, path.concat(k), root || obj))
  }
  return fnLeaf(obj, path, root)
}
type State = 'init' | 'waiting_input' | 'loading' | 'error' | 'ready'
const state: Ref<State> = ref('init')
const errorMessage = ref('')
const dataReport = ref({})
const template = ref<TemplateLayout>({ nodes: [] })
const dataReportId = ref('')
const templateName = ref('')
const dqcReportId = ref('')
const tab = ref(route?.query.tab || 'theme')
const zoom = ref(+localStorage.zoom || 1)
const blueprint = ref(localStorage.blueprint !== 'false')
watch(zoom, () => (localStorage.zoom = zoom.value))
watch(blueprint, () => (localStorage.blueprint = blueprint.value))

// Composables
function onStoreChange(store: any) {
  idb.set(templateName.value, { undo: JSON.parse(JSON.stringify(store.undo)) })
}

const { store } = useStore(template, onStoreChange)
function onComplete(variables: any) {
  const runId = route.query.runId
  if (runId) return
  updateData(variables)
}
function onError(error: string) {
  errorMessage.value = error
  state.value = 'error'
}
const { dataReports, templates, initBuilder, initDataReport, context } = useBuilderContext({ onComplete, onError })
const { getNode, virtualPageAdd } = useLayout(store, template)

const templateCss = ref<string[]>([])
async function updateStyles() {
  if (!template.value.theme?.stylesheet) return
  const stylesheetName = template.value.theme.stylesheet

  const stylesheetsWithName = await stylesheetService.findManyByName({ name: stylesheetName })
  const stylesheet = stylesheetsWithName?.[0]

  if (!template.value.theme.loadedStylesheets) template.value.theme.loadedStylesheets = {}
  template.value.theme.loadedStylesheets[stylesheetName] = stylesheet
  templateCss.value = await getTemplateCss(template)
}
watch(
  () => template.value.theme?.stylesheet,
  async () => {
    await updateStyles()
  },
  { immediate: true },
)

function onOverflow({
  path,
  isOverflow,
  end,
}: {
  path: number[]
  isOverflow: boolean
  component: string
  end?: number
}) {
  // if (!template.value.nodes) return
  // return
  if (end) {
    const overflownNode = getNode(path)
    const options = { ...overflownNode?.options, start: end }
    virtualPageAdd(path[0], { virtualPath: path, ...overflownNode, options })
  } else if (isOverflow) {
    const page = template.value.nodes[path[0]]
    const newPage = { ...page, nodes: page.nodes?.filter(d => ['footer', 'header'].includes(d.component)) }
    const hasHeader = newPage.nodes.some(d => d.component === 'header')
    const index = hasHeader ? 1 : 0
    const overflownNode = getNode(path)
    newPage.nodes.splice(index, 0, { virtualPath: path, ...overflownNode })
    if (!template.value.overflowPages) template.value.overflowPages = {}
    template.value.overflowPages[path[0]] = newPage
  } else {
    if (template.value.overflowPages?.[path[0]]) {
      delete template.value.overflowPages[path[0]]
    }
  }
}
const theme = computed(() => {
  if (!template.value.theme) return {}
  // Ignore esling warning for unsued variables, we just declre them so they dont end up in the ...variables
  // eslint-disable-next-line
  const { page_size, page_orientation, globalCss, styles, globalTheme, ...variables } = template.value.theme
  const map = v => {
    if (v instanceof Object) return
    if (!isNaN(+v)) return v * 0.25 + 'rem'
    if (v.startsWith('#'))
      return v
        .slice(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16))
        .join(',')
    return v
  }
  function camelCaseToDash(str) {
    // Note fails if the string starts with a capital letter
    return str.replace(/([A-Z])/g, '-$1').toLowerCase()
  }
  return {
    class: ['pdf', page_size, page_orientation],
    variables: Object.fromEntries(Object.entries(variables).map(([k, v]) => [`--${camelCaseToDash(k)}`, map(v)])),
  }
})

const storeActive = $event => {
  if (tab.value === 'theme') return
  store.active = $event
  nextTick(() => {
    const el = document.querySelector('.ring-yellow-400')
    if (!el) return
    scrollTo({ top: el.getBoundingClientRect().top + window.pageYOffset - 160, behavior: 'smooth' })
  })
}

//! Effects
onMounted(async () => {
  await initBuilder()
  const runId = route.query.runId

  const _stylesheets = await stylesheetService.findMany()
  stylesheets.value = _stylesheets.map(s => s.name)

  if (runId) {
    const run = await get(`data.runs.${runId}`)
    dataReportId.value = run.context.dataReportId
    templateName.value = run.context.template
    // NOTE: why is share run.context.fund_id ?
    const dr = dataReports.value.find(v => v.id === dataReportId.value)
    if (!dr) return
    const runVariablesFromContext = dr.settingVariableParameters.reduce((acc, { name }) => {
      const variable = name === 'lang' ? run.context.language : run.context[name]
      if (variable) {
        return { ...acc, [name]: variable }
      }
      return acc
    }, {})
    initDataReport(dr, runVariablesFromContext)
    zoom.value = 0.8 // for small screen
    const variables = { ...dr?.variables, ...runVariablesFromContext }
    await nextTick()
    await updateData(variables)
  } else {
    dataReportId.value = (route.query.dataReportId as string) || ''
    templateName.value = templates.value.find(v => v === route.query.templateName) || templates.value[0]
    // initVariables(query, metadata?.variables || {})
  }
})

function setDefaultActive(template: any) {
  Object.traverse(
    template,
    () => null,
    (node, path) => {
      if (store.active.length || !node.component || node.component === 'header') return
      store.active = path.filter(p => !['pages', 'nodes'].includes(p)).map(d => parseInt(d))
    },
  )
}

const refreshKey = ref(0)

watch(
  templateName,
  async () => {
    if (!templateName.value) return
    // state.value = 'loading'
    const layout = templateName.value
    updateQuery({ templateName: layout })

    const templatesWithName = await templateService.findManyByName({ name: layout, limit: 50 })
    const firstTemplate = templatesWithName?.[0]

    // todo: choice version here
    const json = migrateLayout(firstTemplate.layout as unknown as TemplateLayout, stylesheets.value)

    // json.layout = migrateLayout(json)
    dataReportId.value = firstTemplate.dataReportId?.toString()
    dqcReportId.value = firstTemplate.dataQualityCheckReportId?.toString() || ''
    const undo = (await idb.get(layout))?.undo || []
    // migrateLayout(data[0].layout)
    store.undo = Object.equal(undo[0], json) ? undo : [json]
    store.undo_skip = true
    store.versions = templatesWithName
    if (tab.value === 'theme' || store.active.length) return
    setDefaultActive(json)
    // state.value = 'ready'
  },
  { immediate: true },
)

async function updateData(variables: Record<string, any>) {
  state.value = 'loading'
  errorMessage.value = ''
  if (!dataReportId.value) return
  dataReport.value = {}
  await nextTick()
  const { data, error } = await dataReportService.run(dataReportId.value, variables, {
    postProcess: 'builder',
    preProcess: true,
  })
  if (error) {
    errorMessage.value = error
    state.value = 'error'
    return
  }
  dataReport.value = data.result
  addDataReportTranslations(dataReport.value)
  state.value = 'ready'
}

// Update data when dataReportId or dataReportVariables change
function _onDataReportUpdate() {
  updateQuery({ dataReportId: dataReportId.value })
  template.value.dataReportId = dataReportId.value
  const runId = route.query.runId
  if (runId) return
  const dr = dataReports.value.find(v => v.id === dataReportId.value || v.id.toString() === dataReportId.value)
  if (!dr) return
  initDataReport(dr)
  // await updateData(variables)
}
// Debounce it to prevent double triggering (when multiple dataReportVariables change)
const onDataReportUpdate = _onDataReportUpdate.debounce(100)
watch(
  [dataReportId],
  async () => {
    onDataReportUpdate()
  },
  { immediate: true, deep: true },
)
watch(
  tab,
  async () => {
    const tabHeader = tab.value
    updateQuery({ tab: tabHeader })
    if (tab.value === 'theme' && !store.active.length) return
    if (tab.value !== 'theme' && store.active.length) return
    if (tab.value === 'theme') return (store.active = [])
    // TODO make this skip the header

    if (store.active.length) {
      return
    }
    setDefaultActive(template.value)
  },
  { immediate: true },
)
</script>
