<template>
  <div class="row" style="display: flex; justify-content: space-between">
    <div class="column">
      <h1>
        <span class="header-title">{{ headerText }}</span>
        <badge v-if="activeTab === 'results'" :count="formattedDataReportResults?.length || 0" />
      </h1>
      <subtitle style="font-style: italic; margin-top: -5px">
        {{ isNew ? t.data_report_page.subtitle.new : t.data_report_page.subtitle.edit }}
      </subtitle>
    </div>
    <div class="column title-action">
      <autocomplete
        v-if="activeTab === 'results'"
        :data="
          filteredDataResults.reduce(
            (acc, v) => (
              drResultsColumns
                .filter(k => k && typeof v[k] === 'string')
                .map(k => ((acc[k] = acc[k] || {}), (acc[k][v[k]] = v[k]))),
              acc
            ),
            {},
          )
        "
        :options="{ placeholder: t.search }"
        :modelValue="activeFilters.reduce((acc, v, k) => (v && v.map(v => acc.push([k, v].join('.'))), acc), [])"
        @update:modelValue="
          $root.$router.push({
            query: Object.assign(
              $event
                .map(v => v.split('.'))
                .group('0')
                .map(g => g.map('1').join('|')),
            ),
          })
        "
      />
      <button v-else class="primary" @click="openStudio" style="margin-left: 8px">
        {{ t.open_studio || 'Open studio' }}
      </button>
    </div>
  </div>

  <tabs v-if="tabs?.length" :tabs="tabs" :activeTab="activeTab" @send-active-tab="handleChangeActiveTab">
    <template #definition>
      <div class="expand template-container block" v-if="loading === false">
        <div class="column">
          <div class="row" v-if="isNew">
            <div class="column">
              <label class="mb2">{{ t.name || 'name' }}</label>
              <input style="width: 400px" type="text" :placeholder="t.name" v-model="name" />
            </div>
          </div>
          <div class="row mt4">
            <div class="column" v-if="isNew">
              <label class="mb2">{{ t.data_report_page.specialty.label }}</label>
              <select v-model="specialtyName" @change="changeSpecialty">
                <option value="" disabled>{{ t.data_report_page.specialty.placeholder }}</option>
                <option v-for="s in specialties" :key="s.name" :value="s.name">{{ s.name }}</option>
              </select>
            </div>
            <template v-else>
              <label class="mb2">{{ t.data_report_page.specialty.edit }} {{ specialtyName }}</label>
            </template>
          </div>
          <label class="mt4 mb2">{{ t.data_report_page.query || 'Query' }}</label>
          <code-editor
            class="query-editor"
            max-height="calc(100vh - 660px)"
            :code="query"
            @update="handleQueryChanges"
          ></code-editor>
          <label class="mt4 mb2">{{ t.data_report_page.variables || 'Variables' }}</label>
          <tooltip-helper v-if="staticVariables.length">
            <template #icon="{ updateDisplay }">
              <span class="ml2 static-tooltip" @mouseover="updateDisplay" @mouseleave="updateDisplay">
                {{ staticVariablesText }}
              </span>
            </template>
            <div class="static-tooltip-text" v-for="name in staticVariables" :key="name">{{ name }}</div>
          </tooltip-helper>
          <code-editor :code="variables" max-height="200px" @update="handleVariableChanges"></code-editor>
        </div>

        <div class="row mt4">
          <button
            class="mr2 primary"
            v-if="!isDataReportProduct || this.$route.query.new"
            :disabled="!hasUnsavedChanges"
            @click="save"
          >
            {{ t.save }}
          </button>
          <button class="mr2 secondary" @click="$router.push($root.appath + 'data-reports')">{{ t.cancel }}</button>
          <button class="mr2 primary" v-if="!isNew && !hasUnsavedChanges" @click="redirectToRunTab">
            {{ t.run }}
          </button>
        </div>
      </div>
    </template>

    <template #run>
      <div class="expand template-container block">
        <data-report-run :id="id" />
      </div>
    </template>

    <template #results>
      <div class="expand template-container block" v-if="loading === false">
        <data-report-results
          :dataReportDefinitionId="id"
          @send-data-results-informations="handleDataReportResultsInformations"
          :formattedDataReportResults="filteredDataResults"
        />
      </div>
    </template>
  </tabs>
</template>

<script>
import { ref } from 'vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import specialtyService from '@100-m/hauru/src/services/SpecialtyService'
import Badge from '@100-m/hauru/src/components/ui/badge.vue'
import Autocomplete from '@100-m/hauru/src/components/ui/autocomplete.vue'
import { generateStudioUrl } from '@100-m/hauru/src/utils/generateStudioUrl'

export const additions = {}
export default {
  components: { Autocomplete, Badge },
  setup() {
    const formattedDataReportResults = ref([])
    const drResultsColumns = ref([])

    const handleDataReportResultsInformations = drResultsFormatted => {
      formattedDataReportResults.value = drResultsFormatted.dataReportResults
      drResultsColumns.value = drResultsFormatted.drResultsColumns
    }
    return { formattedDataReportResults, drResultsColumns, handleDataReportResultsInformations }
  },
  data() {
    return {
      loading: true,
      queries: [],
      variables: '{}',
      id: 0,
      name: '',
      query: '',
      specialtyName: '',
      hasUnsavedChanges: false,
      isNew: false,
      specialties: [],
      drLoaded: {},
      staticVariables: [],
      drResult: [],
      activeTab: 'definition',
      search: '',
    }
  },
  async mounted() {
    await this.init()
  },

  methods: {
    async init() {
      this.isNew = !this.$route.query.id

      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab
        $root.$router.push({ query: {} })
      }

      this.hasUnsavedChanges = this.$route.query.isImported

      this.loading = true
      this.isDataReportProduct = isNaN(parseInt(this.$route.query.id))
      this.id = this.isDataReportProduct ? this.$route.query.id : parseInt(this.$route.query.id)

      if (this.$route.query.id) {
        this.drLoaded = await this.getQuery(this.id)
        this.query = this.drLoaded.query
        this.name = this.drLoaded.name
        this.specialtyName = this.drLoaded.specialtyName
        try {
          this.variables = JSON.stringify(this.drLoaded.variables, null, 2)
        } catch (e) {
          this.variables = '{}'
        }
      } else {
        this.name = ''
        this.id = 0
        this.variables = '{}'
        this.query = 'query {}'
        await this.getSpecialties()
      }

      this.loading = false
    },
    handleChangeActiveTab(tab) {
      this.activeTab = tab
    },
    handleQueryChanges(e) {
      this.query = e
      this.hasUnsavedChanges = true
    },
    handleVariableChanges(e) {
      this.variables = e
      this.hasUnsavedChanges = true
      this.staticVariablesChanges()
    },
    async getQuery(id) {
      return await dataReportService.getById(id)
    },
    async getSpecialties() {
      this.specialties = await specialtyService.list()
    },
    async changeSpecialty() {
      const specialty = this.specialties.find(s => s.name === this.specialtyName)
      this.variables = `{\n${specialty.settings.map(s => `"${s.name}": "${s.defaultValue || ''}"`).join(',\n')}\n}`
    },
    async save() {
      let variables = {}
      if (!this.name) {
        $root.toast({ description: this.t.data_report_page.error.invalidName, type: 'error', timeout: 5000 })
        return
      }
      if (!this.specialtyName) {
        $root.toast({ description: this.t.data_report_page.error.noSpecialty, type: 'error', timeout: 5000 })
        return
      }
      try {
        variables = JSON.parse(this.variables)
      } catch (e) {
        $root.toast({ description: this.t.data_report_page.error.invalidVariables, type: 'error', timeout: 5000 })
        return
      }
      if (this.isNew) {
        try {
          await dataReportService.create({
            name: this.name,
            query: this.query,
            variables,
            specialtyName: this.specialtyName,
          })
          this.$router.push($root.appath + 'data-reports')
        } catch (e) {
          $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        } finally {
          this.hasUnsavedChanges = false
        }
      } else {
        try {
          await dataReportService.update(this.id, { name: this.name, query: this.query, variables })
          $root.toast({ description: $root.t.saved || 'Saved', type: 'success', timeout: 5000 })
        } catch (e) {
          $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        } finally {
          this.hasUnsavedChanges = false
        }
      }
    },
    openStudio() {
      return window.open(this.studioLink, '_blank')
    },
    staticVariablesChanges() {
      try {
        const variablesName = Object.keys(JSON.parse(this.variables))
        const specialtyVariableNames = []
        if (this.isNew) {
          this.specialties
            .find(s => s.name === this.specialtyName)
            ?.settings.forEach(s => {
              specialtyVariableNames.push(s.name)
            })
        } else {
          this.drLoaded.settingVariableParameters.forEach(s => {
            specialtyVariableNames.push(s.name)
          })
        }

        this.staticVariables = variablesName.filter(v => !specialtyVariableNames.includes(v))
      } catch (e) {}
    },
    redirectToRunTab() {
      this.handleChangeActiveTab('run')
    },
  },
  computed: {
    headerText() {
      return this.isNew
        ? `${this.t.data_report_page.header.new}`
        : `${this.t.data_report_page.header.edit} ${this.name}`
    },
    activeFilters() {
      const params = this.$route.query
      return params.map(filter_value => filter_value.split('|'))
    },
    filteredDataResults() {
      if (!this.activeFilters) {
        return this.formattedDataReportResults
      }
      return this.formattedDataReportResults.filter(f =>
        Object.entries(this.activeFilters).every(([k, vs]) => vs.some(v => f[k] === v)),
      )
    },
    url() {
      return `${config.graphqlEndpoint}/dr/${this.id}/run${this.varUrl}`
    },
    varUrl() {
      if (!this.variables) {
        return ''
      }

      let variables

      try {
        variables = JSON.parse(this.variables)
      } catch (e) {
        return ''
      }

      return '?runVariables=' + encodeURIComponent(JSON.stringify(variables))
    },
    studioLink() {
      return generateStudioUrl({ query: this.query, variables: this.variables })
    },
    staticVariablesText() {
      return this.staticVariables.length > 1
        ? this.t.data_report_page.staticVariables.replace('<XX>', this.staticVariables.length)
        : this.t.data_report_page.staticVariable
    },
    tabs() {
      return [
        { name: 'definition', title: $root.t.definition },
        ...(!this.isNew ? [{ name: 'run', title: $root.t.run }] : []),
        ...(!this.isNew && parseInt(this.id) ? [{ name: 'results', title: $root.t.results }] : []),
      ]
    },
  },
}
</script>

<style scoped>
.header-title {
  margin-right: 8px;
}

.title-action {
  flex: 1;
  align-items: flex-end;
}

.template-container {
  padding-left: 24px;
  padding-right: 24px;
}
.mr2 {
  margin-right: 8px;
}
.ml2 {
  margin-left: 8px;
}
.mt4 {
  margin-top: 16px;
}
.mb2 {
  margin-bottom: 8px;
}
.data-report button svg {
  height: 16px;
  width: 16px;
}
.data-report .buttons button {
  margin-right: 5px;
}

.static-tooltip {
  font: var(--p1);
}
.static-tooltip-text {
  font: var(--p2);
}
label {
  font: var(--h3);
}
.query-editor {
  min-height: 20vh;
}
</style>
