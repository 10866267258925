type GetBuilderPreviewUrlArgs = {
  isDataReport: boolean
  asof?: string
  run?: {
    id?: number
    asof?: string
    fundId?: string | number
    shareId?: string | number
    template?: string
    domain?: string
    language?: string
  }
}

export const getBuilderPreviewUrl = ({ isDataReport, run }: GetBuilderPreviewUrlArgs) => {
  if (isDataReport) {
    return platform.mode === 'history'
      ? `${location.origin}/builder/preview?runId=${run?.id}`
      : `${location.origin}/#/builder/preview?runId=${run?.id}`
  }

  const basePath = `/${run?.fundId}-${run?.shareId}/${run?.template}`

  const url = new URL(`${location.origin}/digital-edition${basePath}`)

  url.searchParams.set('app', 'default')
  url.searchParams.set('cache', 'false')
  run?.domain && url.searchParams.set('domain', run.domain)
  run?.language && url.searchParams.set('lang', run.language)
  run?.asof && url.searchParams.set('asof', run.asof)

  return url.toString()
}
