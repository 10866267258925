import { ComponentNode, LayoutNode } from '../builder'

export type ResolvedTableOfContent = { title: string; pageNumber: string | null }

export const getTableOfContentByTargetComponentName = ({
  state,
  targetComponentName = 'section',
  footerComponentName = 'footer',
}: {
  state: LayoutNode[]
  targetComponentName?: string
  footerComponentName?: string
}): ResolvedTableOfContent[] => {
  return state.reduce<ResolvedTableOfContent[]>((acc, page, pageNumber) => {
    const sectionComponent = page.nodes?.find(block => (block as ComponentNode)?.component === targetComponentName)

    // Check next page if not has section component
    if (!sectionComponent) return acc

    const footerComponent = page.nodes?.find(block => (block as ComponentNode)?.component === footerComponentName)
    const item: ResolvedTableOfContent = {
      title: (sectionComponent as ComponentNode)?.options?.title || '',
      pageNumber: footerComponent ? String(pageNumber + 1) : null,
    }

    acc.push(item)

    return acc
  }, [])
}
