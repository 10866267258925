<template>
  <loading-icon v-if="loading" :size="36" style="margin: auto; padding: 12px 0" />
  <div v-else class="user-input-actions">
    <label>{{ t.instruction }}</label>
    <textarea v-model="managementComment.value"></textarea>

    <div class="row action-buttons">
      <button class="ac primary" @click.stop="submitComment">
        <div class="row nowrap">
          <div class="column">
            <svg-icon class="icon" name="pt-icon-tick" />
          </div>
          <div class="column">{{ t.submit }}</div>
        </div>
      </button>
      <button class="ac secondary" @click.stop="rejectComment">
        <div class="row nowrap">
          <div class="column">
            <svg-icon class="icon" name="pt-icon-cross" />
          </div>
          <div class="column">{{ t.reject }}</div>
        </div>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted } from 'vue'
import { useManagementComments } from '../../composables/useManagementComments'

type ManagementComment = {
  id: number
  period: string
  date: Date
  fund_id: string
  lang: string
  value: string
}

const { getManagementComment } = useManagementComments()

const props = defineProps<{
  run: any
}>()

const managementComment = ref<ManagementComment | { value: string }>({ value: '' })
const loading = ref<boolean>(false)

const emit = defineEmits<{
  (e: 'submit', payload: { run: any; managementComment: ManagementComment | { value: string } }): void
  (e: 'reject'): void
}>()

onMounted(async () => {
  loading.value = true

  await findManagementComment()

  loading.value = false
})

const submitComment = () => {
  emit('submit', { run: props.run, managementComment: managementComment.value })
}

const rejectComment = () => {
  emit('reject')
}

const findManagementComment = async () => {
  const managementCommentVariables = {
    fundId: props.run.context.fundId,
    date: new Date(props.run.context.date).toISOString().split('T')[0],
    period: props.run.context.period,
    lang: props.run.context.language,
  }

  const response = await getManagementComment(managementCommentVariables)

  managementComment.value = response || {
    ...managementCommentVariables,
    fund_id: managementCommentVariables.fundId,
    fundId: undefined,
    value: '',
  }
}
</script>

<style scoped>
.user-input-actions {
  width: 100%;
}

.icon {
  margin-right: 8px;
  fill: var(--primary);
}

.ac.primary .icon {
  fill: white;
}

.action-buttons {
  width: 100%;
  justify-content: space-around;
  margin-top: 18px;
}
</style>
