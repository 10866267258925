<style scoped>
.mr2 {
  margin-right: 8px;
}
.mt2 {
  margin-top: 8px;
}
.compact {
  height: 24px;
}
</style>

<template>
  <block>
    <h2>Docker status</h2>
    <spreadsheet
      v-if="allServices.docker"
      class="stripped expand nosort"
      :data="allServices.docker"
      :options="{ columns: ['Name', 'RunningFor', 'Status'], editable: false }"
    ></spreadsheet>
    <div v-else>No available information</div>
  </block>

  <!--block>
    <h2>Service status</h2>
    <div v-if="allServices.services">Coming soon</div>
    <div v-else>No available information</div>
  </block-->

  <block>
    <h2>Data source web services</h2>
    <block>
      <h3>Response time</h3>
      <spreadsheet
        v-if="dataSourceWebServices && dataSourceWebServices.responseTimes"
        class="stripped expand nosort"
        :data="dataSourceWebServices.responseTimes"
        :options="{ columns: ['endpoint', 'durationMs', 'itemCount'], editable: false }"
      ></spreadsheet>
      <div v-else>No available information</div>
    </block>
    <block>
      <h3>Response missing fields</h3>
      <spreadsheet
        v-if="dataSourceWebServices && dataSourceWebServices.missingFields"
        class="stripped expand nosort"
        :data="dataSourceWebServices.missingFields"
        :options="{ columns: ['endpoint', 'missingFields'], editable: false }"
      ></spreadsheet>
      <div v-else>No available information</div>
    </block>
  </block>

  <block>
    <h2>Data source missing column definitions</h2>
    <spreadsheet
      v-if="missingColumnDefinitions"
      class="stripped expand nosort"
      :data="missingColumnDefinitions"
      :options="{ columns: ['table', 'missingColumns'], editable: false }"
    ></spreadsheet>
    <div v-else>No available information</div>
  </block>

  <block>
    <h2>Hasura data</h2>
    <spreadsheet
      class="stripped expand nosort"
      :data="hasuraData"
      :options="{ columns: ['key', 'value', 'default'], editable: false }"
    >
      <template v-slot:cell-key="{ line }">
        <div class="mr2" :tt="line.description">{{ line.key }}</div>
      </template>
      <template v-slot:cell-value="{ line }">
        <div class="mr2">{{ line.value }}</div>
      </template>
      <template v-slot:cell-default="{ line }">
        <div class="mr2">{{ line.default }}</div>
        <button class="compact primary" @click="setHasuraKey(line.key, line.default)">Set</button>
      </template>
    </spreadsheet>

    <div class="row mt2">
      <div class="mr2">Set arbitrary key:</div>
      <div class="mr2"><input class="compact" type="text" v-model="hasuraKey" /></div>
      <div class="mr2">with value:</div>
      <div class="mr2"><input class="compact" type="text" v-model="hasuraValue" /></div>
      <button
        class="mr2 compact primary"
        :disabled="hasuraKey.trim() === '' || hasuraValue.trim() === '' || !isValidHasuraKeyValue"
        @click="setFreeHasuraKey"
      >
        Set
      </button>
      <div
        v-if="hasuraValue.trim() !== '' && !isValidHasuraKeyValue"
        style="color: red; font-size: 12px; padding-top: 4px"
      >
        Not a valid JSON value
      </div>
    </div>
  </block>

  <block>
    <h2>Impress Database migrations</h2>
    <spreadsheet
      class="stripped expand nosort"
      :data="dbMigrations"
      :options="{
        columns: ['migration_name', 'started_at', 'finished_at', 'rolled_back_at', 'applied_steps_count'],
        editable: false,
      }"
    ></spreadsheet>
  </block>

  <block>
    <h2>Impress Database structure</h2>
    <block v-for="[schema, tableInfo] in Object.entries(dbStructure)">
      <h3>Schema: {{ schema }}</h3>
      <block v-for="[table, columns] in Object.entries(tableInfo)">
        <div>Table: {{ table }}</div>
        <spreadsheet
          class="stripped expand nosort"
          :data="columns"
          :options="{ columns: ['column_name', 'data_type', 'is_nullable'], editable: false }"
        ></spreadsheet>
      </block>
    </block>
  </block>

  <block>
    <h2 style="color: red">Danger zone</h2>
    <div class="row mt2">
      <button class="compact primary" @click="clearDbCache" tt="Clear cached data coming from the database">
        Clear database cache
      </button>
    </div>
  </block>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import statusService from '@100-m/hauru/src/services/StatusService'
import technicalAuditService from '@100-m/hauru/src/services/TechnicalAuditService'

const dbStructure = ref({})
const dbMigrations = ref([])
const allServices = ref({})

const missingColumnDefinitions = ref([])
const dataSourceWebServices = ref({})
const hasuraData = ref([])

const hasuraKey = ref('')
const hasuraValue = ref('')

onMounted(async () => {
  dbStructure.value = (await statusService.getDbStructure()) || {}
  dbMigrations.value = (await statusService.getDbMigrations()) || []
  missingColumnDefinitions.value.push({
    table: 'ids_inventory',
    missingColumns: (await technicalAuditService.getMissingColumnDefinitions('ids_inventory')).join(', ') || 'none',
  })
  dataSourceWebServices.value = await technicalAuditService.checkDataSourceWebServices()
  if (dataSourceWebServices.value) {
    dataSourceWebServices.value.missingFields = dataSourceWebServices.value.missingFields.map(item => ({
      endpoint: item.endpoint,
      missingFields: item.missingFields ? item.missingFields.join(', ') || 'none' : 'N/A',
    }))
  }
  hasuraData.value = (await getHasuraData()) || []
  allServices.value = (await statusService.getAllServices()) || {}
})

const isValidHasuraKeyValue = computed(() => {
  try {
    JSON.parse(hasuraValue.value)
    return true
  } catch (e) {
    return false
  }
})

async function setHasuraKey(key, value) {
  await set(key, JSON.parse(value))
  hasuraData.value = (await getHasuraData()) || []
}

async function getHasuraData() {
  const data = [
    {
      key: 'config.templateTpl',
      value: JSON.stringify(await get('/config/templateTpl')),
      description: 'The Builder url',
      default: `"${document.location.href.split($root.app)[0]}builder/"`,
      // for example: https://kc.nx.digital/impress/#/builder/
    },
    /* {
      key: 'data.run_id',
      value: JSON.stringify(await get('/data/run_id')),
      description: 'The latest run ID used in the app',
      default: 'todo',
    }, */
    {
      key: 'data.commands',
      value: JSON.stringify(await get('/data/commands')),
      description: 'The commands available in the workflow engine',
      default: '["generate", "saveDataReportResult", "dataQualityCheckReport"]',
    },
    {
      key: 'data.runs_years',
      value: JSON.stringify(await get('/data/runs_years')),
      description: 'The default year to filter the runs',
      default: '{"2024":true}', // TODO 3 last years found in DB
    },
  ]
  console.log('getHasuraData', data)
  return data
}

async function setFreeHasuraKey() {
  await setHasuraKey(hasuraKey.value, hasuraValue.value)
}

async function clearDbCache() {
  try {
    const response = await statusService.clearDbCache()
    $root.toast({ description: response, type: 'success', timeout: 5000 })
  } catch (e) {
    $root.toast({ description: e.message, type: 'error', timeout: 5000 })
  }
}
</script>
