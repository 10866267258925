<template>
  <builder-chart class="builder-pie" v-bind="{ ...props, data, nxChartOptions, chartOptions }">
    <template #tooltip-center><div></div></template>
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { pie as api } from '../composables/builderOptions'
import { pie } from './stories'
import { BuilderProps } from '../composables/builderComponent'

const props = defineProps<BuilderProps>()
// const count = computed(() => (Array.isArray(props.data) && props.data.length) || 10)
// const paletteFn = computed(() => (idx: number) => `rgb(var(--primary), ${(1 - idx / count.value).toFixed(2)})`)
const nxChartOptions = computed(() => {
  // debugger
  return {
    viz: 'pie',
    lib: 'nx',
    variant: props.options?.variant || 'donut',
    // palette: Array.from({ length: count.value }, (_, idx) => paletteFn.value(idx)),
  }
})

function parseLegendPosition(position?: string) {
  if (!position || position === 'default') return 'bottom'
  if (position === 'floating') return 'absolute'
  return position
}

const chartOptions = computed(() => {
  return {
    legendOptions: {
      showValues: true,
      position: parseLegendPosition(props.options.legendPosition),
      shape: props.options?.legendShape || 'square',
      orientation: props.options?.legendOrientation || 'vertical',
    },
    ...props.chartOptions,
  }
})
</script>

<script lang="ts">
export default {
  api,
  styles: {
    '.builder-pie': {
      name: 'Chart',
      css: ``,
    },
    '.builder-pie .legend-color': {
      name: 'Legend Color',
      css: `width: 12px;
height: 12px;`,
    },
    '.builder-pie .legend-value': '',
    '.builder-pie .legend-label': '',
    '.builder-pie .nx-pie circle': {
      name: 'Inner Circle',
      css: ``,
    },
    '.builder-pie .nx-pie path': {
      name: 'Pie Slice',
      css: ``,
    },
  },
  story: pie,
}
</script>
<style scoped>
.builder-pie :deep(.nx-pie) {
  max-width: 200px;
}
</style>
