<template>
  <div class="flex flex-col items-center justify-center" v-if="morningstarRating">
    <div class="flex items-center space-x-2">
      <div v-if="options.showLabel">
        <span
          :style="{ ...variantStyle(options.labelStyle), fontSize: `${options.starSize * 3}px` }"
          class="flex items-center justify-center"
        >
          {{ translate('morningstar_rating') || 'morningstar_rating' }}
        </span>
      </div>
      <div>
        <div class="flex items-center justify-center space-x-1 first:pl-[1px]">
          <span v-for="i in morningstarRating" :key="i">
            <svg
              :class="`h-${options.starSize} w-${options.starSize} text-primary fill-current`"
              viewBox="0 0 13 13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.97.843l1.466 4.418 4.655.028-3.75 2.76 1.412 4.435L6.97 9.771l-3.782 2.713 1.41-4.436L.852 5.29l4.654-.028z"
                fill-rule="evenodd"
                :fill="options.style"
              />
            </svg>
          </span>
        </div>
        <div class="mt-2 flex items-center justify-center pl-[6px]" v-if="options.showLogo">
          <img
            :class="`h-${options.starSize}`"
            src="../../../assets/images/Morningstar_Logo.svg"
            alt="Morningstar Logo"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="item-centers flex justify-center">
    <span class="text-black">{{ translate('no_morningstar_rating') || 'no_morningstar_rating' }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const { data, options, context } = defineProps(['data', 'options', 'context'])

function translate(value: string) {
  return context.translations[value] || value
}

const morningstarRating = computed(() => {
  if (!data?.morningstar_rating) {
    return null
  } else {
    return +data?.morningstar_rating
  }
})

const variantStyle = (style: string): { [key: string]: string } => {
  if (style === 'simple') {
    return {
      color: '#6b7280',
    }
  } else if (style === 'bold') {
    return {
      color: '#000000',
      'font-weight': 'bold',
    }
  } else {
    return {
      color: '#000000',
    }
  }
}
</script>
<script lang="ts">
export default {
  api: {
    style: {
      label: 'Star Color',
      default: () => '#333333',
      attrs: {
        type: 'color',
      },
    },
    starSize: {
      label: 'Star Size',
      default: () => 4,
      attrs: {
        type: 'number',
      },
    },
    showLogo: {
      label: 'Show Morningstar logo',
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
      default: () => true,
    },
    showLabel: {
      label: 'Show Label',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    labelStyle: {
      label: 'Label Style',
      hide: ({ options }) => !options?.showLabel,
      default: () => 'normal',
      select: () => {
        return ['normal', 'bold', 'simple']
      },
      attrs: {
        type: 'text',
      },
    },
  },
}
</script>
