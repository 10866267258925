import { RestService } from './RestService'

export class StatusService extends RestService {
  constructor() {
    super()
    this.baseRoute = 'status/'
  }

  async getDbStructure() {
    return await this.get(`${this.baseRoute}db-structure`)
  }

  async getDbMigrations() {
    return await this.get(`${this.baseRoute}db-migrations`)
  }

  async getAllServices() {
    return await this.get(`${this.baseRoute}all-services`)
  }

  async clearDbCache() {
    return await this.post(`cache/clear`)
  }
}

const service = new StatusService()
export default service
