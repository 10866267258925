import {
  IGridColumnsArgs,
  IGridContainer,
  IGridHeaderState,
  IGridItemData,
  IGridItemMetadata,
  IGridMetadata,
  IGridActions,
  IGridNodesArgs,
  IGridSelectionArgs,
  IGridTheme,
  IGridView,
  IThemeState,
  useColumns,
  useHashList,
  useNodes,
  useScrollState,
  useSelection,
  watchImmediate,
} from '@hauru/common'
import { reactiveComputed } from '@vueuse/shared'
import { reactive, readonly, ref, shallowReactive, onUnmounted } from 'vue'
import { useElementSize } from '@vueuse/core'
import { isFunction } from 'lodash'

export interface IGridStateLimited {
  idColumn: string
  ref: HTMLElement | null
  data: IGridItemData[]
  dataMap: ReturnType<typeof useHashList<IGridItemData>>
  metadata: IGridMetadata
  autosizeColumn: null | ((header: IGridHeaderState) => void)
}

export interface IPivot {
  rows: string[]
  columns: string[]
  aggregates: string[]
}

export interface IPlot {
  viz: 'bar' | 'line' | 'area' | 'pie' | 'dot' | 'box' | 'radar' | 'surface' | 'treemap' | 'worldmap'
  x: string
  y: string
  z?: string
  color?: string
}

type IArgs = IGridColumnsArgs &
  IGridNodesArgs &
  IGridSelectionArgs & {
    grid: IGridContainer | null
    display: 'grid' | 'transpose' | 'pivot' | 'plot'
    actions: IGridActions[]
    transpose: boolean
    find: string
    findDebounce: number
    filter: null | { [key: string]: string } | ((line: IGridItemData) => boolean)
    pivot: IPivot
    plot: IPlot
    showScrollbars: boolean
    showIndex: boolean
    showCheckbox: boolean
    onKeydownWrapper: (state: any) => (event: KeyboardEvent) => void
    autosizeColumn: null | ((state: IGridStateLimited, header: IGridHeaderState) => void)
    autoexpandColumns: boolean
    expandHeadersOnClick: boolean
    breakLimitCollapse: number
    breakLimitExpand: number
    breakLimit: number
  }

export type IGridState = ReturnType<typeof useGridState>

export function useGridState({
  grid = null,
  display = 'grid',
  actions = ['hide', 'group', 'sort', 'space', 'find', 'info'],
  transpose = false,
  find = '',
  filter = null,
  pivot = { rows: [], columns: [], aggregates: [] },
  plot = { viz: 'bar' } as IPlot,
  showScrollbars = true,
  showIndex = true,
  showCheckbox = true,
  onKeydownWrapper = onKeydownDefault,
  autosizeColumn = null,
  autoexpandColumns = false,
  expandHeadersOnClick = false,
  findDebounce = 0,
  breakLimitCollapse = 1500,
  breakLimitExpand = 3000,
  breakLimit = 50000,
  ...args
}: Partial<IArgs> = {}) {
  const idColumn = grid?.metadata?.id_column ?? 'id'

  const containerRef = ref<HTMLElement | null>(null)
  const scrollState = useScrollState({ showScrollbars })

  const state = reactive({
    idColumn,
    ref: null as HTMLElement | null,
    setRef: (value: any) => (state.ref = value),
    emit: (() => {}) as (event: string, ...args: any[]) => void,
    setEmit: (value: any) => (state.emit = value),
    container: {
      ...useElementSize(containerRef),
      ref: containerRef,
      setRef: (value: any) => (containerRef.value = value),
      getRef: () => containerRef,
    },
    /**
     * Data array of current grid
     */
    data: shallowReactive([] as IGridItemData[]),
    /**
     * Map of data items keyd by their id
     */
    dataMap: useHashList<IGridItemData>({ getKey: item => item[idColumn] as string | number }),
    /**
     * Metadata of current grid
     */
    metadata: shallowReactive({} as IGridMetadata),
    /**
     * Nodes metadata used to render the grid on the screen
     */
    nodes: useNodes({ ...args }),
    /**
     * State of column headers
     */
    columns: useColumns({ idColumn, ...args }),
    /**
     * Selection state (both cell ranges and items)
     */
    selection: useSelection({ ...args }),
    views: [] as IGridView[],
    setViews: (value: IGridView[]) => (state.views = value),
    display: display as 'grid' | 'transpose' | 'pivot' | 'plot' | string,
    actions: actions as IGridActions[],
    setActions: (value: IGridActions[]) => (state.actions = value),
    toggleDisplay: (value: 'grid' | 'transpose' | 'pivot' | 'plot') =>
      (state.display = state.display === value ? 'grid' : value),
    transpose,
    find,
    setFind: (value: string) => (state.find = value),
    findDebounce,
    setFindDebounce: (value: number) => (state.findDebounce = value),
    filter,
    setFilter: (value: {}) => (state.filter = value),
    pivot,
    setPivot: (value: IArgs['pivot']) => (state.pivot = value),
    plot,
    setPlot: (value: IArgs['plot']) => (state.plot = value),

    isProcessing: false,
    setProcessing: (value: boolean) => (state.isProcessing = value),
    fontSize: 12,
    setFontSize: (value: number) => (state.fontSize = value),
    calculateNodes,
    setGrid,
    showIndex,
    setShowIndex: (value?: boolean) => (state.showIndex = value ?? !state.showIndex),
    showCheckbox,
    setShowCheckbox: (value?: boolean) => (state.showCheckbox = value ?? !state.showCheckbox),

    scroll: scrollState,
    autoSizeColumns,
    autoexpandColumns,
    rowWidth: { value: 0 },
    maxRowWidthPx: { value: 0 },
    selectedRow: { value: null as number | null },
    onKeydown: (() => {}) as (event: KeyboardEvent) => void,
    gridTheme: {} as Partial<IGridTheme>,
    setGridTheme: (value: IThemeState, args: any) => (state.gridTheme = value.reactiveComputedThemeType('grid', args)!),
    editedRows: [] as number[],
    setEditedRows: (value: number[]) => (state.editedRows = value),
    autosizeColumn: isFunction(autosizeColumn) ? (header: IGridHeaderState) => autosizeColumn(state, header) : null,
    expandHeadersOnClick,
    setMetadata,
    breakLimitCollapse,
    breakLimitExpand,
    breakLimit,
    bypassBreakLimit: false,
    setBypassBreakLimit: (value: boolean) => (state.bypassBreakLimit = value),
  })

  state.onKeydown = onKeydownWrapper(state)
  state.columns.setState(state)
  state.selection.setState(state)
  state.nodes.setState(state)

  state.rowWidth = reactiveComputed(() => ({
    value: state.columns.visibleAll.reduce((acc, c) => acc + c.width, 0),
  }))
  state.maxRowWidthPx = reactiveComputed(() => ({
    value: state.rowWidth.value + state.nodes.maxLeftOffset + state.nodes.maxRightOffset,
  }))

  // watchImmediate(
  //   () => state.emit,
  //   () => {
  //     console.log('state.emit', state.emit)
  //   },
  // )

  state.selectedRow = reactiveComputed(() => ({
    value:
      state.selection.range.fromRow === state.selection.range.toRow ||
      (state.selection.range.fromRow && !state.selection.range.toRow)
        ? state.selection.range.fromRow
        : null,
  }))

  watchImmediate(
    () => state.selectedRow.value,
    () => state.setProcessing(false),
  )

  watchImmediate(
    () => state.nodes.rowHeight,
    () => state.scroll.setRowHeight(state.nodes.rowHeight),
  )
  watchImmediate(
    () => state.columns.width,
    () => state.scroll.setColumnWidth(state.columns.width),
  )
  watchImmediate(
    () => state.container.height,
    () => state.scroll.setContainerHeight(state.container.height),
  )
  watchImmediate(
    [() => state.container.width, state.columns.visibleFreezedWidth, () => state.nodes.maxLeftOffset],
    () =>
      state.scroll.setContainerWidth(
        state.container.width - state.columns.visibleFreezedWidth.value - state.nodes.maxLeftOffset,
      ),
  )
  watchImmediate(state.nodes.totalHeight, () => state.scroll.setContentHeight(state.nodes.totalHeight.value))
  watchImmediate(
    [() => state.maxRowWidthPx, state.columns.visibleFreezedWidth, () => state.nodes.maxLeftOffset],
    () => {
      state.scroll.setContentWidth(
        state.maxRowWidthPx.value - state.columns.visibleFreezedWidth.value - state.nodes.maxLeftOffset,
      )
      state.scroll.setHorizontalOffset(state.columns.visibleFreezedWidth.value + state.nodes.maxLeftOffset)
    },
  )

  const DEBUG = false

  /**
   * Updates the grid data in the state
   * @param grid
   */
  function setGrid(grid: IGridContainer) {
    if (DEBUG) console.log('update grid data in state', grid)
    state.data = shallowReactive(grid.data)
    state.dataMap.replace(grid.data)
    state.metadata = shallowReactive(grid.metadata)
    state.columns.updateColumns()
    if (DEBUG) console.log('datamap', JSON.parse(JSON.stringify(state.dataMap.list)))
    if (DEBUG) console.time('calculateNodes')
    calculateNodes()
    if (DEBUG) console.timeEnd('calculateNodes')
  }

  function setMetadata(metadata) {
    state.metadata = shallowReactive(metadata)
  }

  /**
   * Calculates the tree nodes of the grid. If metadata exists, create the tree using metadata. Otherwise, create the tree using rows from state data.
   */
  function calculateNodes() {
    if (!state.metadata?.tree) {
      const rows = state.data.map(r => r[idColumn] as string | number)
      state.nodes.createTree(null, null, rows)
    } else {
      state.nodes.createTree(null, state.metadata.tree as IGridItemMetadata, null)
    }
  }

  let animationFrame = 0
  function updateScroll() {
    state.scroll.throttled.update()
    animationFrame = requestAnimationFrame(updateScroll)
  }
  animationFrame = requestAnimationFrame(updateScroll)

  /**
   * Automatically adjust size of column header as soon as they are visible.
   * It starts a watcher that will clear itself when all columns are resized.
   */
  function autoSizeColumns() {
    if (!state) return

    const i = setInterval(() => {
      const todo = Array.from(state.columns.yieldColumns()).filter(header => {
        if (header.isAutoSized) return false
        return !header.autoSize()
      })

      if (state.autoexpandColumns) {
        const columnsWidth = state.columns.visibleAll.reduce((acc, c) => acc + c.width, 0)
        const containerWidth = state.container.width
        // Take all available space if columns are smaller than container
        if (columnsWidth < containerWidth) {
          let roundedAjustedWidth = containerWidth
          for (const header of state.columns.visibleAll) {
            const width = Math.round(header.width * (containerWidth / columnsWidth) * 0.97)
            roundedAjustedWidth -= width
            if (header === state.columns.visibleAll.at(-1))
              header.setWidth(width + roundedAjustedWidth - state.nodes.maxLeftOffset)
            else header.setWidth(width)
          }
        }
      }

      if (todo.length === 0) clearInterval(i)
    }, 100)

    onUnmounted(() => {
      clearInterval(i)
    })
  }

  onUnmounted(() => {
    cancelAnimationFrame(animationFrame)
  })

  return readonly(state)
}

/**
 * Handles keyboard events for grid navigation.
 *
 * This function uses arrow keys for direction-based navigation and tab for horizontal navigation.
 * When a cell is selected, arrow keys will select adjacent cells, and tab will select cells horizontally.
 * When a cell is not selected, arrow keys will scroll the grid, and tab won't do anything.
 *
 * @param event - The KeyboardEvent object from the event listener.
 */
function onKeydownDefault(state: any) {
  return function onKeydown(event: KeyboardEvent) {
    const { enable, range } = state.selection
    const isSelected = enable.cells && range.fromRow !== null
    const runEvent = () => {
      // if (event.key === 'Escape') return state.selection.clear()
      // if (event.key === 'Enter') return state.selection.selectDown(event.shiftKey)
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'a' || event.key === 'A') return state.selection.selectAll()
      }
      if (isSelected) {
        if (event.key === 'ArrowUp') return state.selection.selectUp(event.shiftKey)
        if (event.key === 'ArrowDown') return state.selection.selectDown(event.shiftKey)
        if (event.key === 'ArrowLeft') return state.selection.selectLeft(event.shiftKey)
        if (event.key === 'ArrowRight') return state.selection.selectRight(event.shiftKey)
        if (event.key === 'Tab' && event.shiftKey) return state.selection.selectLeft()
        if (event.key === 'Tab') return state.selection.selectRight()
      }
      if (event.key === 'ArrowUp') return state.scroll.offsetTop(-state.nodes.rowHeight)
      if (event.key === 'ArrowDown') return state.scroll.offsetTop(state.nodes.rowHeight)
      if (event.key === 'ArrowLeft') return state.scroll.offsetLeft(-state.columns.width)
      if (event.key === 'ArrowRight') return state.scroll.offsetLeft(state.columns.width)
      if (event.key === 'Escape') {
        state.selection.select()
        return state.selection.items.deselectAll()
      }
      if (event.key === 'PageUp') return state.scroll.offsetTop(-state.scroll.containerHeight)
      if (event.key === 'PageDown') return state.scroll.offsetTop(state.scroll.containerHeight)
      if (event.key === 'Home') return state.scroll.offsetLeft(-100000000)
      if (event.key === 'End') return state.scroll.offsetLeft(100000000)
      return 'DEFAULT'
    }
    const result = runEvent()
    if (result !== 'DEFAULT') event.preventDefault()
  }
}
