<!-- eslint-disable vue/no-mutating-props -->
<template>
  <!-- tree-grid-header-actions -->
  <div qa-tree-grid-header-actions class="sticky top-0 bg-white" :class="[gridZIndex.actions]">
    <div class="flex gap-1">
      <template v-if="state.display === 'pivot'">
        <nx-dropdown theme="grid.basic">
          <template #button="{ shown }">
            <nx-button qa-pivot-rows theme="grid.actions.rows" :selected="shown">Rows</nx-button>
          </template>
          <div
            qa-pivot-rows-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.basic?.contentClass"
          >
            <div :class="theme.dropdown.grid.basic?.mainContentClass">
              <div class="flex shrink-0 grow flex-col">
                <template v-for="column in columnsPivot" :key="column.label">
                  <nx-list-item
                    class="!cursor-default !gap-0 text-sm"
                    type="grid"
                    v-if="!column.direction || column.direction === 'rows'"
                  >
                    <div class="flex w-8">
                      <nx-switch @click="updatePivot('rows', column.label)" :checked="column.direction === 'rows'" />
                    </div>
                    <div class="truncate pl-2 pr-1">{{ column.label }}</div>
                    <div class="ml-auto"></div>
                    <div class="text-inactive text-xs">{{ column.type }}</div>
                  </nx-list-item>
                </template>
              </div>
            </div>
          </div>
        </nx-dropdown>
        <nx-dropdown theme="grid.basic">
          <template #button="{ shown }">
            <nx-button qa-pivot-columns theme="grid.actions.columns" :selected="shown">Columns</nx-button>
          </template>
          <div
            qa-pivot-columns-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.basic?.contentClass"
          >
            <div :class="theme.dropdown.grid.basic?.mainContentClass">
              <div class="flex grow flex-col">
                <template v-for="column in columnsPivot" :key="column.label">
                  <nx-list-item
                    class="!cursor-default !gap-0 text-sm"
                    type="grid"
                    v-if="!column.direction || column.direction === 'columns'"
                  >
                    <div class="flex w-8">
                      <nx-switch
                        @click="updatePivot('columns', column.label)"
                        :checked="column.direction === 'columns'"
                      />
                    </div>
                    <div class="truncate pl-2 pr-1">{{ column.label }}</div>
                    <div class="ml-auto"></div>
                    <div class="text-inactive text-xs">{{ column.type }}</div>
                  </nx-list-item>
                </template>
              </div>
            </div>
          </div>
        </nx-dropdown>
        <nx-dropdown theme="grid.basic">
          <template #button="{ shown }">
            <nx-button qa-pivot-aggregates theme="grid.actions.aggregates" :selected="shown">Aggregates</nx-button>
          </template>
          <div
            qa-pivot-aggregates-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.basic?.contentClass"
          >
            <div :class="theme.dropdown.grid.basic?.mainContentClass">
              <div class="flex grow flex-col">
                <template v-for="column in columnsPivot" :key="column.label">
                  <nx-list-item
                    class="!cursor-default gap-2 text-sm"
                    type="grid"
                    v-if="!column.direction || column.direction === 'aggregates'"
                  >
                    <nx-switch
                      class="shrink-0"
                      @click="updatePivot('aggregates', column.label)"
                      :checked="column.direction === 'aggregates'"
                    />
                    <div class="flex flex-col truncate">
                      <div class="truncate leading-none">{{ column.label }}</div>
                      <div class="text-inactive text-xs">{{ column.type }}</div>
                    </div>
                    <div class="ml-auto"></div>
                    <select
                      class="text-inactive -mx-2 w-12 cursor-pointer border-0 bg-transparent p-1 text-right text-xs capitalize underline"
                      @change="updatePivot('aggregates', column.label, $event.target.value)"
                      @click.stop
                      v-if="column.direction === 'aggregates'"
                    >
                      <option
                        :selected="aggregate === fn"
                        v-for="fn in ['count', 'unique', 'list'].concat(
                          {
                            number: ['sum', 'avg', 'min', 'max'],
                            boolean: ['sum'],
                            date: ['min', 'max'],
                            datetime: ['min', 'max'],
                            time: ['min', 'max'],
                          }[column.type] || [],
                        )"
                        :key="fn"
                      >
                        {{ fn }}
                      </option>
                    </select>
                  </nx-list-item>
                </template>
              </div>
            </div>
          </div>
        </nx-dropdown>
        <nx-button theme="grid.actions.swap" @click="swapPivot"></nx-button>
      </template>

      <template v-if="state.display === 'plot'">
        <nx-dropdown theme="grid.actions.basic">
          <template #button="{ shown }">
            <nx-button qa-plot-visualisation theme="grid.actions.plot" :selected="shown">Visualization</nx-button>
          </template>
          <div
            qa-plot-visualisation-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.actions.plot?.contentClass"
          >
            <div :class="theme.dropdown.grid.actions.plot?.mainContentClass">
              <div class="flex grow flex-col">
                <nx-list-item
                  class="gap-2 text-sm"
                  :class="{ '!bg-emerald-100': state.plot.viz === viz }"
                  type="grid"
                  @click="updatePlot('viz', viz)"
                  v-for="viz in [
                    'line',
                    'area',
                    'bar',
                    'pie',
                    'dot', // 'scatter',
                    // 'box', // 'candlestick',
                    // 'radar', // 'spider',
                    'surface',
                    // 'treemap',
                    // 'worldmap',
                  ]"
                  :key="viz"
                >
                  <div class="capitalize">{{ viz }}</div>
                  <div class="ml-auto"></div>
                  <div class="-mr-2 h-[30px] w-[50px] bg-[#f5f5f5] text-[#6e6e6e]">
                    <div class="h-full w-full" :class="'i-plot/' + viz"></div>
                  </div>
                </nx-list-item>
              </div>
            </div>
          </div>
        </nx-dropdown>
        <nx-dropdown theme="grid.basic">
          <template #button="{ shown }">
            <nx-button qa-plot-x-axis theme="grid.actions.basic" :selected="shown">Axis</nx-button>
          </template>
          <div class="flex divide-x">
            <template v-for="letter in ['x', 'y', 'z', 'category']">
              <div class="flex grow flex-col" v-if="columnsPlot.find(c => c.compat.includes(letter))">
                <div class="flex h-8 items-center justify-center font-bold">
                  {{ letter.length === 1 ? letter.upper() + '-Axis' : letter.capitalize() }}
                </div>
                <div class="max-h-80 overflow-y-auto">
                  <nx-list-item
                    class="gap-2 text-base"
                    :class="{ '!bg-emerald-100': column.direction === letter }"
                    type="grid"
                    @click="updatePlot(letter, column.label)"
                    v-for="column in columnsPlot.filter(c => c.compat.includes(letter))"
                    :key="column.label"
                  >
                    <div class="truncate">{{ column.label }}</div>
                    <div class="ml-auto"></div>
                    <div class="text-inactive text-xs">{{ column.type }}</div>
                  </nx-list-item>
                </div>
              </div>
            </template>
          </div>
        </nx-dropdown>
        <nx-dropdown theme="grid.actions.basic">
          <template #button="{ shown }">
            <nx-button qa-plot-options theme="grid.actions.basic" :selected="shown">Options</nx-button>
          </template>
          <div
            qa-plot-options-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.actions.plot?.contentClass"
          >
            <div :class="theme.dropdown.grid.actions.plot?.mainContentClass">
              <div class="flex grow flex-col gap-1 px-2">
                <label class="flex h-6 items-center justify-between gap-2" v-if="['bar'].includes(state.plot.viz)">
                  Horizontal:
                  <input
                    class="m-0"
                    type="checkbox"
                    :checked="state.plot.horizontal"
                    @input="updatePlot('horizontal', $event.target.checked)"
                  />
                </label>
                <label
                  class="flex h-6 items-center justify-between gap-2"
                  v-if="['bar', 'area'].includes(state.plot.viz) && state.plot.category"
                >
                  Stacked:
                  <input
                    class="m-0"
                    type="checkbox"
                    :checked="state.plot.stack"
                    @input="updatePlot('stack', $event.target.checked)"
                  />
                </label>
                <label
                  class="flex h-6 items-center justify-between gap-2"
                  v-if="['bar', 'area'].includes(state.plot.viz) && state.plot.category"
                >
                  Full-Stacked:
                  <input
                    class="m-0"
                    type="checkbox"
                    :checked="state.plot.rebase"
                    @input="updatePlot('fullstack', $event.target.checked)"
                  />
                </label>
                <hr class="-mx-2 -mb-4 mt-1 p-2" v-if="['bar', 'area'].includes(state.plot.viz)" />
                <label
                  class="flex h-6 items-center justify-between gap-2"
                  v-if="!['dot', 'surface'].includes(state.plot.viz)"
                >
                  Aggregate:
                  <select
                    class="text-inactive w-12 cursor-pointer border-0 bg-transparent p-1 text-right text-xs capitalize underline"
                    @change="updatePlot('aggregate', $event.target.value)"
                    @click.stop
                  >
                    <option
                      :selected="state.plot.aggregate === fn"
                      v-for="fn in ['sum', 'count', 'avg', 'min', 'max']"
                      :key="fn"
                    >
                      {{ fn }}
                    </option>
                  </select>
                </label>
                <label
                  class="flex h-6 items-center justify-between gap-2"
                  v-if="!['dot', 'surface'].includes(state.plot.viz)"
                >
                  Limit:
                  <input
                    class="h-6 w-8"
                    type="number"
                    min="0"
                    max="20"
                    :placeholder="['bar', 'pie'].includes(state.plot.viz) ? 10 : ''"
                    :value="state.plot.limit || null"
                    @input="updatePlot('limit', +$event.target.value)"
                  />
                </label>
                <label
                  class="flex h-6 items-center justify-between gap-2"
                  v-if="state.plot.limit && !['dot', 'surface'].includes(state.plot.viz)"
                >
                  Display Other:
                  <input
                    class="m-0"
                    type="checkbox"
                    :checked="state.plot.other ?? true"
                    @input="updatePlot('other', $event.target.checked)"
                  />
                </label>
                <hr class="-mx-2 -mb-4 mt-1 p-2" />
                <!-- <label class="flex items-center justify-between gap-2 h-6">
                  Title:
                  <input
                    class="h-6 max-w-1/2"
                    type="text"
                    :value="state.plot.title"
                    @input="updatePlot('title', $event.target.value)"
                  />
                </label> -->
                <label class="flex h-6 items-center justify-between gap-2">
                  Display Label:
                  <input
                    class="m-0"
                    type="checkbox"
                    :checked="state.plot.label ?? state.plot.viz !== 'pie'"
                    @input="updatePlot('label', $event.target.checked)"
                  />
                </label>
                <label class="flex h-6 items-center justify-between gap-2">
                  Display Legend:
                  <input
                    class="m-0"
                    type="checkbox"
                    :checked="state.plot.legend ?? !!state.plot.category"
                    @input="updatePlot('legend', $event.target.checked)"
                  />
                </label>
                <label class="flex h-6 items-center justify-between gap-2">
                  Display Tooltip:
                  <input
                    class="m-0"
                    type="checkbox"
                    :checked="state.plot.tooltip ?? true"
                    @input="updatePlot('tooltip', $event.target.checked)"
                  />
                </label>
                <div
                  v-if="additionalOptions.find(k => state.plot[k] != null)"
                  class="-mx-2 -mb-1 flex shrink-0 grow-0 basis-auto gap-2 border-t border-solid border-gray-200 px-2 py-1"
                >
                  <nx-button theme="grid.subactions.basic" @click="updatePlot('reset')">Reset Options</nx-button>
                </div>
              </div>
            </div>
          </div>
        </nx-dropdown>
      </template>

      <template v-for="action in gridActions" :key="action">
        <nx-dropdown theme="grid.actions.hide" v-if="action === 'hide' && state.display === 'grid'">
          <template #button="{ shown }">
            <nx-button
              qa-hidden-columns
              theme="grid.actions.hide"
              :active="!!hiddenColumnsCount"
              :selected="shown"
              :show-label="!props.displayOnlyIconActions"
            >
              <div v-if="hiddenColumnsCount">
                {{ hiddenColumnsCount }}
                {{ hiddenColumnsCount > 1 ? gridsTheme?.labels.hiddenColumns : gridsTheme?.labels.hiddenColumn }}
              </div>
              <div v-else>{{ gridsTheme?.labels.hideColumns }}</div>
            </nx-button>
          </template>
          <div
            qa-hidden-columns-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.actions.hide?.contentClass"
          >
            <div :class="theme.dropdown.grid.actions.hide?.mainContentClass">
              <nx-dropdown-select-list
                :list="state?.columns?.headers?.list as any[]"
                :toggle="header => toggleColumn(header)"
                :checked="header => header.show"
                group="hide"
                label-key="label"
                @change="changeColumns"
              />
            </div>
            <div class="flex shrink-0 grow-0 basis-auto gap-2 border-t border-solid border-gray-200 px-2 py-1">
              <nx-button
                qa-hide-all
                theme="grid.subactions.hideAll"
                :disabled="hiddenColumnsCount === props.state?.columns?.headers?.list?.length"
                @click="hideAllColumns"
              />
              <nx-button
                qa-show-all
                theme="grid.subactions.showAll"
                :disabled="hiddenColumnsCount === 0"
                @click="showAllColumns"
              />
            </div>
          </div>
        </nx-dropdown>

        <nx-dropdown theme="grid.actions.group" v-if="action.startsWith('group') && state.display === 'grid'">
          <template #button="{ shown }">
            <nx-button
              qa-group-by
              theme="grid.actions.group"
              :active="!!state?.columns.groupBy.list.length"
              :selected="shown"
              :show-label="!props.displayOnlyIconActions"
            >
              {{ state?.columns.groupBy.list.length || '' }}
              {{ state?.columns.groupBy.list.length ? gridsTheme?.labels.groups : gridsTheme?.labels.group }}
            </nx-button>
          </template>
          <div
            qa-group-by-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.actions.group?.contentClass"
          >
            <div class="flex shrink-0 grow-0 basis-auto flex-col gap-1 border-b border-solid border-gray-200 px-2 py-1">
              <div class="flex gap-2">
                <nx-button
                  qa-collapse-all
                  theme="grid.subactions.collapseAll"
                  :disabled="
                    !state.nodes.depth ||
                    state.nodes.collapsed.list.length === state.nodes.countNodes ||
                    state.breakLimitCollapse < state.nodes.countNodes
                  "
                  @click="state.nodes.collapsed.collapseAll()"
                />
                <nx-button
                  qa-expand-all
                  theme="grid.subactions.expandAll"
                  :disabled="
                    !state.nodes.depth ||
                    state.nodes.collapsed.list.length === 0 ||
                    state.breakLimitCollapse < state.nodes.countNodes
                  "
                  @click="state.nodes.collapsed.expandAll(100)"
                />
              </div>
              <div class="flex items-center gap-1 text-xs text-gray-700" v-if="levels.length > 1">
                <div class="grow text-right">Expand up to level:</div>
                <nx-button
                  v-for="level in levels"
                  :qa-expand-level="level"
                  :key="level"
                  theme="grid.subactions.expandToLevel"
                  :disabled="!state.nodes.depth || state.breakLimitExpand < state.nodes.countNodes"
                  @click="state.nodes.collapsed.expandToLevel(level)"
                >
                  {{ level }}
                </nx-button>
              </div>
            </div>
            <div v-if="action === 'group'" :class="theme.dropdown.grid.actions.group?.mainContentClass">
              <nx-dropdown-select-list
                :list="state?.columns.groupBy.list"
                :toggle="(el: string) => state?.columns.groupBy.toggle(el)"
                :checked="true"
                @change="state?.columns.groupBy?.change"
                group="group-by"
              />
              <nx-dropdown-select-list
                :list="state?.columns?.headers?.list?.filter(k => !state?.columns.groupBy.is(k.label)) ?? []"
                :toggle="(el: ITreeGridTableColumn) => state?.columns.groupBy.toggle(el.label)"
                :checked="false"
                label-key="label"
              />
            </div>
            <div
              v-if="state?.columns.groupBy.list.length"
              class="flex shrink-0 grow-0 basis-auto gap-2 border-t border-solid border-gray-200 px-2 py-1"
            >
              <nx-button
                qa-group-by-empty
                theme="grid.subactions.removeGroups"
                @click="state?.columns.groupBy.empty()"
              />
            </div>
          </div>
        </nx-dropdown>

        <nx-dropdown theme="grid.actions.sort" v-if="action === 'sort' && state.display === 'grid'">
          <template #button="{ shown }">
            <nx-button
              qa-sort
              theme="grid.actions.sort"
              :active="!!state?.columns.sortBy.list.length"
              :selected="shown"
              :show-label="!props.displayOnlyIconActions"
            >
              {{ state?.columns.sortBy.list.length || '' }}
              {{ state?.columns.sortBy.list.length! > 1 ? gridsTheme?.labels.sorts : gridsTheme?.labels.sort }}
            </nx-button>
          </template>
          <div
            qa-sort-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.actions.sort?.contentClass"
          >
            <div :class="theme.dropdown.grid.actions.sort?.mainContentClass">
              <nx-dropdown-select-list
                :list="state?.columns.sortBy.list"
                :toggle="(el: IGridSortColumn) => state?.columns.sortBy.toggle(el.label)"
                :checked="true"
                group="sort"
                @change="state?.columns.sortBy.change"
              >
                <template #item="{ element }: { element: IGridSortColumn }">
                  <div
                    class="flex h-full flex-1 items-center gap-1 truncate"
                    @click.stop="state?.columns.sortBy.reverse(element.label)"
                  >
                    <div
                      v-if="element.order === 1"
                      class="shrink-0 cursor-pointer"
                      :class="
                        element.order === 1 ? gridsTheme?.icons?.sortAscending : gridsTheme?.icons?.sortDescending
                      "
                    ></div>
                    <div v-else class="shrink-0 cursor-pointer" :class="gridsTheme?.icons?.sortDescending"></div>
                    <div class="truncate">
                      {{ element.label }}
                    </div>
                  </div>
                </template>
              </nx-dropdown-select-list>
              <nx-dropdown-select-list
                :list="state?.columns?.headers?.list?.filter(k => state?.columns.sortBy.getOrder(k.label) === 0) ?? []"
                :toggle="
                  (el: ITreeGridTableColumn) => state?.columns.sortBy.toggle(el.label, undefined, el.labelPathArray)
                "
                :checked="false"
                label-key="label"
              />
            </div>
            <div
              v-if="state?.columns.sortBy.list.length"
              class="flex shrink-0 grow-0 basis-auto gap-2 border-t border-solid border-gray-200 px-2 py-1"
            >
              <nx-button qa-sort-by-empty theme="grid.subactions.removeSorts" @click="state?.columns.sortBy.empty()" />
            </div>
          </div>
        </nx-dropdown>

        <nx-dropdown
          theme="grid.actions.basic"
          v-if="action === gridActions.find(v => ['transpose', 'pivot', 'plot', 'finder'].includes(v))"
        >
          <template #button="{ shown }">
            <nx-button qa-mode theme="grid.actions.basic">
              <template v-if="state.display === 'grid'">
                <div class="i-[mdi/table] !h-4 !-scale-x-100"></div>
                Grid
              </template>
              <template v-else-if="state.display === 'transpose'">
                <div class="i-[mdi/table-pivot] !h-4 !-scale-x-100"></div>
                Transpose
              </template>
              <template v-else-if="state.display === 'pivot'">
                <div class="i-[ic/outline-pivot-table-chart] !h-4 !-scale-x-100"></div>
                Pivot
              </template>
              <template v-else-if="state.display === 'plot'">
                <div class="i-[ic/outline-bar-chart] !h-[18px] !-scale-x-100"></div>
                Chart
              </template>
              <template v-else-if="state.display === 'finder'">
                <div class="i-[mdi/format-columns] !h-[18px] !-scale-x-100"></div>
                Finder
              </template>
            </nx-button>
          </template>
          <div
            qa-mode-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.actions.mode?.contentClass"
          >
            <div :class="theme.dropdown.grid.actions.mode?.mainContentClass">
              <nx-list-item
                class="text-base"
                :class="{ '!bg-emerald-100': state.display === 'grid' }"
                type="grid"
                @click="state.toggleDisplay('grid')"
              >
                <div class="i-[mdi/table] !h-4 !-scale-x-100"></div>
                Grid
              </nx-list-item>
              <nx-list-item
                class="text-base"
                :class="{ '!bg-emerald-100': state.display === 'transpose' }"
                type="grid"
                @click="state.toggleDisplay('transpose')"
                v-if="gridActions.includes('transpose')"
              >
                <div class="i-[mdi/table-pivot] !h-4 !-scale-x-100"></div>
                Transpose
              </nx-list-item>
              <nx-list-item
                class="text-base"
                :class="{ '!bg-emerald-100': state.display === 'pivot' }"
                type="grid"
                @click="state.toggleDisplay('pivot')"
                v-if="gridActions.includes('pivot')"
              >
                <div class="i-[ic/outline-pivot-table-chart] !h-4 !-scale-x-100"></div>
                Pivot
              </nx-list-item>
              <nx-list-item
                class="text-base"
                :class="{ '!bg-emerald-100': state.display === 'plot' }"
                type="grid"
                @click="state.toggleDisplay('plot')"
                v-if="gridActions.includes('plot')"
              >
                <div class="i-[ic/outline-bar-chart] !h-[18px] !-scale-x-100"></div>
                Chart
              </nx-list-item>
              <nx-list-item
                class="text-base"
                :class="{ '!bg-emerald-100': state.display === 'finder' }"
                type="grid"
                @click="state.toggleDisplay('finder')"
                v-if="gridActions.includes('finder')"
              >
                <div class="i-[mdi/format-columns] !h-[18px] !-scale-x-100"></div>
                Finder
              </nx-list-item>
            </div>
          </div>
        </nx-dropdown>

        <nx-dropdown theme="grid.actions.density" v-if="action === 'density'" :popperHideTriggers="[]">
          <template #button="{ shown }">
            <nx-button qa-density theme="grid.actions.density" :selected="shown" />
          </template>
          <div
            qa-density-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.actions.density?.contentClass"
          >
            <div :class="theme.dropdown.grid.actions.density?.mainContentClass">
              <div class="flex grow flex-col">
                <div class="px-4 py-1 text-xs text-gray-700">Select row height</div>
                <div class="flex items-center gap-2 px-4 py-4">
                  <div :class="gridsTheme?.icons?.lineDensitySmall"></div>

                  <input
                    qa-row-height-input
                    id="default-range"
                    type="range"
                    min="16"
                    max="32"
                    step="2"
                    :value="props.state.nodes.rowHeight"
                    @input="
                      $event => {
                        props.state.nodes.setRowHeight(parseInt($event?.target?.value ?? 16))
                        // props.state.nodes.setNodeOffset([
                        //   props.state.nodes.rowHeight,
                        //   props.state.nodes.getNodeOffsetRight(),
                        //   props.state.nodes.getNodeOffsetBottom(),
                        //   props.state.nodes.getNodeOffsetLeft(),
                        // ])
                        // necessary to recalculate node heights
                        state.calculateNodes()
                        state.nodes.calculateDimensions()
                      }
                    "
                    class="vsg-unset h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 dark:bg-gray-300"
                  />
                  <div :class="gridsTheme?.icons?.lineDensityLarge"></div>
                </div>
                <div class="px-4 pb-1 pt-2 text-xs text-gray-700">Select text size</div>
                <nx-list-item class="text-sm" type="grid" @click="props.state.setFontSize(12)">
                  <div :class="gridsTheme?.icons?.textSmall"></div>
                  Small text
                </nx-list-item>
                <nx-list-item class="text-[13px]" type="grid" @click="props.state.setFontSize(13)">
                  <div :class="gridsTheme?.icons?.textMedium"></div>
                  Medium text
                </nx-list-item>
                <nx-list-item class="text-base" type="grid" @click="props.state.setFontSize(14)">
                  <div :class="gridsTheme?.icons?.textLarge"></div>
                  Large text
                </nx-list-item>
              </div>
            </div>
          </div>
        </nx-dropdown>

        <nx-dropdown theme="grid.actions.filter" v-if="action === 'filter'">
          <template #button="{ shown }">
            <nx-button qa-filter class="relative" theme="grid.actions.filter" :selected="shown">
              <div
                class="absolute right-0 top-0 inline-block h-3 w-3 rounded-full bg-orange-400 text-center text-xs leading-3 text-white empty:invisible"
              >
                {{ (state.filter || []).length || '' }}
              </div>
            </nx-button>
          </template>
          <div
            qa-filter-dropdown
            class="flex flex-col overflow-hidden"
            :class="theme.dropdown.grid.actions.filter?.contentClass"
          >
            <div :class="theme.dropdown.grid.actions.filter?.mainContentClass">
              <div class="-mt-1 flex shrink-0 grow-0 basis-auto gap-2 border-b border-solid border-gray-200 px-2 py-1">
                <input />
                <input />
                <nx-button theme="grid.subactions.basic" @click="">+</nx-button>
              </div>
              <div class="flex grow flex-col text-xs">
                <template v-for="(filter, i) in state.filter">
                  <div class="px-2 hover:bg-gray-100" @click="state.setFilter(state.filter.filter(v => v !== filter))">
                    <label class="flex h-4 items-center gap-1 hover:bg-gray-100" v-for="(value, key, j) in filter">
                      <div class="font-bold text-orange-500" v-if="j !== 0">AND</div>
                      <div>{{ key }}:</div>
                      <div class="font-bold">{{ value }}</div>
                    </label>
                  </div>
                  <div
                    class="flex h-4 items-center justify-center font-bold text-yellow-500"
                    v-if="state.filter.length - 1 > i"
                  >
                    OR
                  </div>
                </template>
              </div>
              <div
                v-if="(state.filter || []).length"
                class="-mb-1 flex shrink-0 grow-0 basis-auto gap-2 border-t border-solid border-gray-200 px-2 py-1"
              >
                <nx-button theme="grid.subactions.basic" @click="state.setFilter([])">Reset Filters</nx-button>
              </div>
            </div>
          </div>
        </nx-dropdown>

        <input
          qa-search
          :class="[gridsTheme?.actions?.find?.idle, state.find ? gridsTheme?.actions?.find?.active : '']"
          type="search"
          :placeholder="gridsTheme?.actions?.find?.placeholder"
          :value="state.find"
          @input="setFind"
          v-if="action === 'find'"
        />

        <div qa-space v-if="action === 'space'" class="w-4 grow"></div>
        <div v-if="action === 'info'" class="mx-1 my-auto shrink-0 text-xs">
          <b>{{ props.state.nodes.countRows }}</b>
          lines
        </div>

        <slot :name="action" v-if="action.startsWith('slot')"></slot>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { IDraggableChange, IGridActions, IGridSortColumn, IGridState } from '@hauru/common'
import { useTheme, gridZIndex } from '@hauru/common'
import { typeCompatibility } from '../nx-chart/nx-chart.ts'
import { isArray } from 'lodash'
import { computed } from 'vue'

interface IProps {
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the dropdown among the types defined in the theme config
   */
  type?: string
  /**
   * Actions that we want to present in the action toolbar in their order
   * If you pass an object and want to use an action multiple times, just add $ characters to its name
   */
  actions?: IGridActions[] | Record<IGridActions, boolean>
  /*
   * State of the grid
   */
  state: IGridState
  /*
   * property that defines only the display of icons in action buttons
   */
  displayOnlyIconActions?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  actions: () => ['hide', 'group', 'sort'],
})

const emit = defineEmits<{
  (e: 'changeOrderColumns', value: IDraggableChange): void
  (e: 'changeColumnVisibility', value: any): void
  (e: 'changeAllColumnsVisibility', value: boolean): void
}>()

const additionalOptions = [
  'horizontal',
  'stack',
  'fullstack',
  'aggregate',
  'limit',
  'other',
  'title',
  'label',
  'legend',
  'tooltip',
]
const aggregate = computed(() => {
  const aggregateMap = {
    maximum: 'max',
    minimum: 'min',
    values: 'list',
    count: 'count',
    sum: 'sum',
    unique: 'unique',
    avg: 'avg',
  }
  return aggregateMap[props.state.pivot.aggregates[0]] || ''
})

const gridActions = computed(() => {
  if (isArray(props.state.actions)) return props.state.actions.filter(action => action)
  return Object.entries(props.state.actions || {})
    .filter(([action, value]) => value)
    .map(([action]) => action.replaceAll('$', ''))
})

const visibleColumns = computed(() => {
  return props.state?.columns?.headers?.list?.filter(h => h.show)
})

const hiddenColumnsCount = computed(() => {
  return (props.state?.columns?.headers?.list?.length ?? 0) - (visibleColumns.value.length ?? 0)
})

const levels = computed(() => [0, ...Array.from(Array(props.state.nodes.depth).keys()).map(v => v + 1)])

const themeConfig = useTheme()
const gridsTheme = themeConfig.computedThemeType('grid', props)
const theme = themeConfig.computedTheme()

const columnsPivot = computed(() => {
  const { metadata, pivot } = props.state
  return Object.entries(metadata.columns || {})
    .map(([label, column]) => {
      let direction
      if (pivot.aggregates.flat().includes(label)) direction = 'aggregates'
      else if (pivot.columns.includes(label)) direction = 'columns'
      else if (pivot.rows.includes(label)) direction = 'rows'
      return { ...column, direction, label }
    })
    .filter(column => column.type !== 'id')
    .sort(column => {
      if (column.direction === 'rows') return -3
      if (column.direction === 'columns') return -2
      if (column.direction === 'aggregates') return -1
      return 0
    })
})
const updatePivot = (name: 'rows' | 'columns' | 'aggregates', column: string, choice?: string) => {
  // console.log(name, column, choice)
  const pivot = JSON.parse(JSON.stringify(props.state.pivot))
  if (name === 'rows') pivot.rows = pivot.rows.toggle(column)
  if (name === 'columns') pivot.columns = pivot.columns.toggle(column)
  // if (name === 'aggregates') pivot.aggregates = pivot.aggregates.toggle(column)

  // TODO: allow multiple aggregates
  // TODO: allow reorder of rows / columns / aggregates
  if (name === 'aggregates' && !choice) pivot.aggregates = ['count', column]
  if (name === 'aggregates' && choice) pivot.aggregates[0] = choice

  props.state.setPivot(pivot)
}

const columnsPlot = computed(() => {
  const { metadata, plot } = props.state
  const compatibility = metadata.columnsCompatibility || typeCompatibility
  return Object.entries(metadata.columns)
    .filter(([label, column]) => column.type !== 'id')
    .map(([label, column]) => {
      const axis = ['x', 'y', 'z', 'category']
      const direction = axis.find(v => plot[v] === label)
      const compat = axis.filter(v => {
        if (!compatibility[plot.viz][v]) return false
        if (direction) return direction === v
        return compatibility[plot.viz][v].includes(column.type)
      })
      return { ...column, label, direction, compat }
    })
})

const updatePlot = (name: string, value: string) => {
  // console.log(name, value)
  // const plot = JSON.parse(JSON.stringify(props.state.plot))
  const plot = { ...props.state.plot }
  if (name === 'viz') plot.viz = value
  if (name === 'x') plot.x = value
  if (name === 'y') plot.y = value
  if (name === 'z') plot.z = value === plot.z ? null : value
  if (name === 'category') plot.category = value === plot.category ? null : value
  if (name === 'horizontal') plot.horizontal = value
  if (name === 'stack') plot.stack = value
  if (name === 'fullstack') plot.stack = plot.fullstack = value
  if (name === 'aggregate') plot.aggregate = value
  if (name === 'limit' && value) plot.limit = value
  if (name === 'limit' && !value) delete plot.limit
  if (name === 'other') plot.other = value
  if (name === 'title') plot.title = value
  if (name === 'label') plot.label = value
  // if (name === 'label' && value) delete plot.label
  // if (name === 'label' && !value) plot.label = value
  if (name === 'legend') plot.legend = value
  if (name === 'tooltip') plot.tooltip = value
  if (name === 'reset') additionalOptions.forEach(k => delete plot[k])
  props.state.setPlot(plot)
}
const swapPivot = () => {
  props.state.columns.expandAll()
  const pivot = props.state.pivot
  props.state.setPivot({
    ...pivot,
    rows: pivot.columns,
    columns: pivot.rows,
  })
}

const changeColumns = (e: IDraggableChange) => {
  props.state?.columns?.headers?.change(e)
  emit('changeOrderColumns', e)
}

const toggleColumn = (header: any) => {
  header.toggleShow()
  emit('changeColumnVisibility', header)
}

const hideAllColumns = () => {
  props.state.columns.hideAll()
  emit('changeAllColumnsVisibility', false)
}

const showAllColumns = () => {
  props.state.columns.showAll()
  emit('changeAllColumnsVisibility', true)
}

const setFind = (event: Event) => {
  props.state.setFind((event.target as HTMLInputElement).value)
}
const setFilter = (event: Event) => {
  const input = (event.target as HTMLInputElement).value
  try {
    props.state.setFilter(JSON.parse(input))
  } catch (e) {}
  try {
    props.state.setFilter(eval(input))
  } catch (e) {}
}
</script>
