<template>
  <div class="flex flex-col">
    <div v-html="'<style>' + css + '</style>'" v-for="(css, idx) in previewCss" :key="idx"></div>
    <div class="sticky top-0 z-10 w-screen bg-white shadow-lg">
      <div class="flex h-16 w-full items-center space-x-6 border-b px-12">
        <button class="rounded p-2 hover:bg-[#00d9b8]/20" @click="$root.$router.push('/client-edition/stylesheets')">
          <ui-asset :width="30" :height="30" name="icon_home"></ui-asset>
        </button>
        <label class="flex flex-col">
          <div class="w-40 text-xs font-semibold text-[#00d9b8]">Stylesheet</div>
          <select class="flex rounded border-transparent bg-transparent py-0 pl-0" v-model="stylesheetName">
            <option v-for="v in stylesheets" :value="v" :key="v">{{ v }}</option>
          </select>
          <!-- <input type="url" v-model="dataReportId" /> -->
        </label>

        <div v-if="state === 'ready' && !stylesheet?.readOnly" class="flex flex-1 justify-end gap-2">
          <button
            class="relative flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10 disabled:pointer-events-none disabled:opacity-40"
            :class="{ 'cursor-not-allowed': store.undo.length === 1 }"
            :disabled="store.undo.length <= 1"
            @click="undo()"
          >
            <ui-asset :width="20" :height="20" name="icon_undo"></ui-asset>
            <div class="hidden font-semibold 2xl:block">Undo</div>
            <div
              class="absolute -right-1 -top-1 h-4 w-4 truncate rounded-full bg-[#00d9b8]/75 text-[10px] leading-[16px]"
              v-if="store.undo.length > 1"
            >
              {{ store.undo.length - 1 }}
            </div>
          </button>
          <button
            class="relative flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10 disabled:pointer-events-none disabled:opacity-40"
            :class="{ 'cursor-not-allowed': store.redo.length === 0 }"
            @click="redo()"
            :disabled="store.redo.length === 0"
          >
            <ui-asset :width="20" :height="20" name="icon_redo"></ui-asset>
            <div class="hidden font-semibold 2xl:block">Redo</div>
            <div
              class="absolute -right-1 -top-1 h-4 w-4 truncate rounded-full bg-[#00d9b8]/75 text-[10px] leading-[16px]"
              v-if="store.redo.length"
            >
              {{ store.redo.length }}
            </div>
          </button>
          <button
            class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10 disabled:pointer-events-none disabled:opacity-40"
            @click="saveVersion"
          >
            <ui-asset :width="20" :height="20" name="icon_save"></ui-asset>
            <div class="hidden font-semibold 2xl:block">Save</div>
          </button>
          <builderui-version-button
            :versions="store.versions"
            @delete-version="deleteVersion"
            @restore-version="restoreVersion"
            @download-version="downloadVersion"
            @upload-version="uploadVersion"
          ></builderui-version-button>
        </div>
      </div>
    </div>
    <div class="container mx-auto flex flex-col gap-4 bg-white p-4">
      <template v-if="state === 'ready' && stylesheet">
        <h1 class="flex-1 text-3xl font-semibold">Stylesheet {{ stylesheetName }}</h1>
        <section class="flex flex-col gap-12 py-12">
          <div class="flex flex-row gap-12">
            <div class="flex min-w-[140px] flex-col gap-4">
              <h2 class="text-2xl font-bold">Colors</h2>
              <div class="flex flex-row items-center gap-2">
                <label class="flex-1">Primary</label>
                <div class="border-1 rounded border border-gray-100">
                  <input class="!h-6 !w-6 cursor-pointer" v-model="stylesheet.primary" type="color" />
                </div>
              </div>
              <div class="flex flex-row items-center gap-2">
                <label class="flex-1">Secondary</label>
                <div class="border-1 rounded border border-gray-100">
                  <input class="!h-6 !w-6 cursor-pointer" v-model="stylesheet.secondary" type="color" />
                </div>
              </div>
            </div>

            <div class="flex flex-col gap-4">
              <div class="flew-row flex items-center gap-10">
                <h2 class="text-2xl font-bold">Palettes</h2>
                <div>
                  <button @click="addPalette" class="rounded border p-1 text-sm text-black shadow-sm hover:bg-gray-100">
                    + Add palette
                  </button>
                </div>
              </div>
              <div class="palette-grid grid gap-2">
                <template v-for="(palette, idx) in stylesheet.palettes" :key="palette.name">
                  <builderui-palette-input
                    :palette="palette"
                    @update="ev => updatePalette(idx, ev)"
                  ></builderui-palette-input>
                  <div class="ml-2 flex flex-row items-center gap-2" style="font-size: 12px">
                    <div v-if="stylesheet.defaultPalette === idx">Default palette</div>
                    <button class="rounded p-1 hover:bg-gray-100" @click="stylesheet.defaultPalette = idx" v-else>
                      Set as default
                    </button>
                    <button class="rounded p-1 hover:bg-gray-100" @click="deletePalette(idx)">Delete all</button>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <h2 class="text-2xl font-bold">Header logo</h2>
            <div class="flew-row flex items-center">
              <button
                class="rounded !border-0 px-4 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-200"
                @click="triggerLogoInput"
              >
                Import file
                <input class="hidden" type="file" ref="logoInput" accept="image/*" @change="importLogo" />
              </button>
              <span class="ml-4">{{ logo?.name || 'No file imported' }}</span>
              <div class="ml-8 flex h-10 w-10 items-center border-2 border-gray-100" v-if="logoPreview">
                <img :src="logoPreview" alt="Logo Preview" />
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <h2 class="text-2xl font-bold">Font family</h2>
            <div class="w-1/4">
              <select
                v-model="stylesheet.font"
                class="flex-1 rounded !border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-200"
              >
                <option :value="null"></option>
                <optgroup v-if="customFonts.length" label="Custom fonts">
                  <option v-for="font in customFonts" :key="font" :value="font">
                    {{ font }}
                  </option>
                </optgroup>
                <optgroup label="Default fonts">
                  <option v-for="font in defaultFonts" :key="font" :value="font">
                    {{ font }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <h2 class="text-2xl font-bold">Global css</h2>
            <div class="w-[49%]">
              <div class="flex flex-row">
                <textarea
                  class="w-full rounded !border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-200"
                  v-model="stylesheet.globalCss"
                  ref="globalCssTextArea"
                  @input="updateHeight"
                ></textarea>
              </div>
            </div>
          </div>
        </section>
        <div class="flex flex-col">
          <div class="flex flex-row items-center justify-between">
            <h1 class="flex-1 text-2xl font-semibold">Styles</h1>
            <input
              class="flex-1 rounded !border-0 bg-gray-50 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-200"
              v-model="search"
              placeholder="Search style or component"
            />
          </div>
          <div class="flex flex-col divide-y divide-slate-100">
            <section class="style-grid grid gap-6 py-12" v-if="showGlobal">
              <h2 class="text-xl font-bold">Global styles</h2>
              <builderui-style-block
                v-model="stylesheet"
                :componentTag="getComponentTag('block')"
                :component="components['block']"
                componentName="block"
                :search="search"
              ></builderui-style-block>
            </section>
            <section
              class="style-grid grid gap-6 py-12"
              v-for="(component, componentName) in filteredComponents"
              :key="componentName"
            >
              <h2 class="text-xl font-bold">{{ componentName.titleize() }}</h2>
              <builderui-style-block
                class="flex-1"
                v-model="stylesheet"
                :componentTag="getComponentTag(componentName)"
                :component="component"
                :componentName="componentName"
                :search="search"
              ></builderui-style-block>
            </section>
          </div>
        </div>
        <!-- <div>{{ stylesheet }}</div>
        <div>{{ previewCss }}</div> -->
      </template>
      <template v-else-if="state === 'error'">Error</template>
      <template v-else-if="state === 'loading'"><loader></loader></template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, Ref, computed, watch } from 'vue'
import stylesheetService from '@100-m/hauru/src/services/StylesheetService'
import { Stylesheet } from '../builder'
import useStore from '../composables/store'
import { objectEqual } from '../lib/utils'
import {
  deleteFile,
  getAssetPublicUrl,
  readJSONFile,
  uploadAssetImage,
} from '@100-m/hauru/src/applications/builder/lib/assetManagement'
import { parseStylesheet } from '../composables/theme'
import { useComponents } from '../composables/builderComponent'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const { getComponents, getComponentTag } = useComponents()
const search = ref('')
const components = getComponents()
const styledComponents = Object.fromEntries(
  Object.entries(components).filter(([name, component]) => component.styles && name !== 'block'),
)

const showGlobal = computed(() => {
  if (!search.value) return true
  const regex = new RegExp(search.value.toLowerCase(), 'i')
  const blockStyles = Object.keys(components.block.styles) || []
  return regex.test('global styles') || blockStyles.some((style: string) => regex.test(style))
})

const filteredComponents = computed<Record<string, any>>(() => {
  if (!search.value) return styledComponents
  const regex = new RegExp(search.value.toLowerCase(), 'i')
  return Object.fromEntries(
    Object.entries(styledComponents).filter(([name, component]) => {
      if (regex.test(name)) return true
      if (Array.isArray(component.styles)) {
        return component.styles.some((style: string) => regex.test(style))
      }
      return Object.entries(component.styles).some(([styleName, styleData]) => {
        // console.log('styleName', styleName, search.value, regex.test(styleName.titleize()))
        return regex.test(styleName) || regex.test(styleName.titleize()) || regex.test(styleData.name || '')
      })
    }),
  )
})

const defaultFonts = computed<string[]>(() => {
  return ['Arial', 'Courier New', 'MarkPro', 'system-ui', 'Tahoma', 'Verdana'].sort()
})

const customFonts = computed<string[]>(() => {
  return (window.config?.customFonts || []).sort()
})

const logoPreview = computed<string | null>(() => {
  if (logo.value?.buffer) {
    return URL.createObjectURL(new Blob([logo.value.buffer]))
  }

  if (logo.value?.path) {
    return getAssetPublicUrl({ filenameWithPath: logo.value.path })
  }

  return null
})

type State = 'init' | 'loading' | 'error' | 'ready'

//! Variables
const state = ref<State>('init')
const stylesheet: Ref<Stylesheet | null> = ref(null)
const stylesheets: Ref<string[]> = ref([])
const stylesheetName: Ref<string> = ref('')
const logoInput = ref<HTMLButtonElement | null>(null)
const globalCssTextArea = ref<HTMLTextAreaElement | null>(null)
const logo = ref<{ name?: string; path?: string; buffer?: ArrayBuffer } | null>(null)

const { store, undo, redo } = useStore(stylesheet)

async function loadStylesheet(name: string) {
  const stylesheetsWithName = await stylesheetService.findManyByName({ name })
  stylesheet.value = stylesheetsWithName?.[0]

  if (stylesheet.value.logo) {
    logo.value = { name: stylesheet.value.logo.split('/').pop(), path: stylesheet.value.logo }
  }

  const allStylesheets = await stylesheetService.findManyByName({ name, limit: 50 })
  store.versions = allStylesheets
  store.undo = [JSON.parse(JSON.stringify(stylesheet.value))]
}

async function uploadLogo() {
  if (!logo.value?.path && !logo.value?.buffer) {
    return
  }

  const fileExtension = logo.value?.name?.split('.').pop()

  const filename = `logo-${(store.versions[0].id || 1) + 1}.${fileExtension}`

  try {
    const { bucketPath } = await uploadAssetImage({
      arrayBuffer: logo.value?.buffer as ArrayBuffer,
      name: filename,
      subFolder: `${stylesheet.value?.name}/logo`,
      isPrivateFile: false,
    })

    if (!bucketPath) {
      return $root.toast({ description: 'Error while uploading image', type: 'error', timeout: 5000 })
    }

    return bucketPath
  } catch (error) {
    return $root.toast({ description: 'Error while uploading image', type: 'error', timeout: 5000 })
  }
}

async function saveVersion() {
  if (!stylesheet.value) {
    return
  }

  const hasUpdatedLogo = !!logo.value?.buffer

  if (!hasUpdatedLogo && objectEqual(store.versions[0], stylesheet.value)) {
    return
  }

  stylesheet.value.logo = await uploadLogo()

  const version = await stylesheetService.create({ stylesheetInput: stylesheet.value })
  store.versions = [version].concat(store.versions)
  store.undo = [JSON.parse(JSON.stringify(stylesheet.value))]
  store.redo = []
  store.undo_skip = true
}

async function deleteVersion(version: any) {
  if (store.versions.length === 1) {
    return
  }

  await stylesheetService.deleteOne(version.id)

  if (version.logo) {
    await deleteFile({ filenameWithPath: version.logo, isPrivateFile: false })
  }

  store.versions = store.versions.filter(v => v !== version)
}
async function restoreVersion(version: any) {
  if (objectEqual(version, stylesheet.value)) {
    return
  }

  if (version.logo) {
    logo.value = { name: version.logo?.split('/').pop(), path: version.logo }
  }

  stylesheet.value = version
}

async function downloadVersion() {
  if (!stylesheet.value) return
  // @ts-expect-error string prototype extended
  JSON.stringify(stylesheet.value).download(
    // @ts-expect-error Date prototype extended
    `${stylesheet.value.name}-${new Date().format('YYYY-MM-DD.hhhmmm')}.json`,
  )
}

async function uploadVersion(event: Event) {
  const data = (await readJSONFile(event)) as Stylesheet
  if (!data || !stylesheet.value) return
  data.name = stylesheet.value.name
  stylesheet.value = data
}

const previewCss = computed(() => {
  if (!stylesheet.value) return ''
  return parseStylesheet(stylesheet.value)
})

function updateHeight() {
  if (!globalCssTextArea.value) return
  globalCssTextArea.value.style.height = 'auto'
  globalCssTextArea.value.style.height = `${globalCssTextArea.value.scrollHeight}px`
}

onMounted(async () => {
  state.value = 'loading'

  const _stylesheets = await stylesheetService.findMany()
  stylesheets.value = _stylesheets.map(s => s.name)

  stylesheetName.value = (route?.query?.stylesheetName as string) || stylesheets.value[0]
  try {
    await loadStylesheet(stylesheetName.value)
    // stylesheet.value.palette = ['#000000', '#ffffff', '#ff0000', '#00ff00']
    state.value = 'ready'
  } catch (e) {
    state.value = 'error'
  }
  updateHeight()
})

watch(stylesheetName, async () => {
  if (state.value === 'loading') return
  state.value = 'loading'
  await loadStylesheet(stylesheetName.value)
  router.push({ query: { stylesheetName: stylesheetName.value } })
  state.value = 'ready'
})

function addPalette() {
  if (!stylesheet.value) return
  if (!stylesheet.value.palettes) stylesheet.value.palettes = []
  stylesheet.value.palettes.push({
    name: `Palette ${stylesheet.value.palettes.length + 1}`,
    colors: [stylesheet.value.primary],
  })
}
function deletePalette(idx: number) {
  if (!stylesheet.value || !stylesheet.value.palettes) return
  stylesheet.value.palettes = stylesheet.value.palettes.filter((d, i) => i !== idx)
}

function updatePalette(index: number, ev: any) {
  if (!stylesheet.value) return
  stylesheet.value.palettes[index] = ev
}

function triggerLogoInput() {
  logoInput.value?.click()
}

async function importLogo(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0]

  if (!file) {
    return 'No file selected'
  }

  const validExtensions = ['jpg', 'jpeg', 'png']
  const fileExtension = file.name.split('.').pop() || ''

  if (!validExtensions.includes(fileExtension.toLowerCase())) {
    return $root.toast({ description: 'Invalid file type (only images are allowed)', type: 'error', timeout: 5000 })
  }

  const reader = new FileReader()
  reader.onload = async event => {
    const arrayBuffer = event.target?.result

    if (!arrayBuffer) {
      return
    }

    logo.value = { name: file.name, buffer: arrayBuffer as ArrayBuffer }
  }

  reader.readAsArrayBuffer(file)
}
</script>
<style scoped>
.palette-grid {
  grid-template-columns: minmax(100px, max-content) 1fr;
}
.style-grid {
  grid-template-columns: 1fr 5fr;
}
</style>
