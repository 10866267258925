import dataReportService from '../../../services/DataReportService.js'
import { reactive, ref, computed } from 'vue'
import { z } from 'zod'

const DOMAIN_FREE_TEXT_REGEX = /^(\d{4}-(\d{2}|Q[1-4])(-\d{2})?)(\|\d{4}-(\d{2}|Q[1-4])(-\d{2})?)?$/

type VariableTypeName =
  | 'fundId'
  | 'shareId'
  | 'shareInfo'
  | 'shareInfoAxis'
  | 'langList'
  | 'domainFreeText'
  | 'parsedFromDomain'

export interface VariableType {
  name: VariableTypeName
  dependencies?: VariableTypeName[]
  unique?: boolean
  uniqueId?: string
  inputType: 'select' | 'input' | 'date' | 'readonly'
  dataReport?: string
  hasArgument?: boolean
  argumentName?: string
  validationSchema?: z.Schema<any>
}

export const variableTypes: Record<VariableTypeName, VariableType> = {
  fundId: {
    name: 'fundId',
    inputType: 'select',
    dataReport: 'fundIdUniqList',
    unique: true,
  },
  shareId: {
    name: 'shareId',
    dependencies: ['fundId'],
    inputType: 'select',
    dataReport: 'shareIdUniqList',
    unique: true,
  },
  shareInfo: {
    name: 'shareInfo',
    dependencies: ['shareId'],
    inputType: 'readonly',
    hasArgument: true,
    dataReport: 'shareInfo',
  },
  shareInfoAxis: {
    name: 'shareInfoAxis',
    inputType: 'readonly',
    dependencies: ['shareId'],
    hasArgument: true,
    dataReport: 'shareInfoAxis',
    argumentName: 'axisPosition',
  },
  langList: {
    name: 'langList',
    inputType: 'select',
    dataReport: 'langList',
    uniqueId: 'lang',
  },
  domainFreeText: {
    name: 'domainFreeText',
    uniqueId: 'domain',
    inputType: 'input',
    unique: true,
    validationSchema: z.string().regex(DOMAIN_FREE_TEXT_REGEX, { message: 'wrong_domain_format' }), // wrong_domain_format => translation key in index.yml
  },
  parsedFromDomain: {
    name: 'parsedFromDomain',
    dependencies: ['fundId', 'domainFreeText'],
    inputType: 'readonly',
    hasArgument: true,
    dataReport: 'parseFromDomain',
  },
}

export interface VariableDefinition {
  name: string
  variableType: VariableTypeName
  scope: 'defaultVariable' | 'partial' | 'runParameter'
  argument?: string | number
}

type PartialRecord<K extends string | number | symbol, T> = {
  [P in K]?: T
} & {
  [P in K]: T
}[K]

export interface VariableOption {
  name: string
  value: any
}

export type VariableDefinitions = VariableDefinition[]
export const defaultVariableSettings: VariableDefinitions = [
  { name: 'fundId', variableType: 'fundId', scope: 'partial' },
  { name: 'shareId', variableType: 'shareId', scope: 'partial' },
  { name: 'horizon', variableType: 'shareInfo', scope: 'partial', argument: 'horizon' },
  { name: 'axis_1', variableType: 'shareInfoAxis', scope: 'partial', argument: 1 },
  { name: 'axis_2', variableType: 'shareInfoAxis', scope: 'partial', argument: 2 },
  { name: 'lang', variableType: 'langList', scope: 'partial' },
  { name: 'domain', variableType: 'domainFreeText', scope: 'runParameter' },
  { name: 'endDate', variableType: 'parsedFromDomain', scope: 'runParameter', argument: 'endDate' },
]

export function useDrVariables({
  onComplete,
  onChange,
  onError,
}: { onComplete?: Function; onChange?: Function; onError?: Function } = {}) {
  const variableSettings = ref<VariableDefinition[]>([])

  const partials = computed(() => variableSettings.value.filter(v => v.scope === 'partial'))
  const runParameters = computed(() => variableSettings.value.filter(v => v.scope === 'runParameter'))

  const inputData = reactive<PartialRecord<string, any>>({})
  const missingDependencies = reactive<PartialRecord<string, any>>({})
  const variables = reactive<PartialRecord<string, any>>({})
  const validationErrors = reactive<PartialRecord<string, any>>({})

  function updateContext() {
    const newContext = getContext()
    Object.keys(newContext).forEach(k => {
      context[k] = newContext[k]
    })
  }

  function getContext() {
    return variableSettings.value.reduce((acc: PartialRecord<VariableTypeName, any>, v) => {
      const varType = variableTypes[v.variableType]
      if (varType.unique || varType.uniqueId) {
        // Kind of a hack, we need both domainFreeText for the dependency detection and the uniqueId for the context
        // TODO: refacto so the uniqueId is used for dependency detection
        acc[varType.name] = variables[v.name]
        if (varType.uniqueId) {
          acc[varType.uniqueId] = variables[v.name]
        }
      }
      return acc
    }, {})
  }

  const context = reactive(getContext())

  async function updateVariableData(variableDefinition: VariableDefinition) {
    const context = getContext()
    const varType = variableTypes[variableDefinition.variableType]

    if (varType.dependencies && varType.dependencies.some(d => !context[d])) {
      missingDependencies[variableDefinition.name] = varType.dependencies.filter(d => !context[d])

      return
    } else {
      missingDependencies[variableDefinition.name] = null
    }

    const value = await getVariableValues(variableDefinition, context, variableDefinition.argument, onError)

    // If its a readonly (not an input), the computed value is the variable value
    if (varType.inputType === 'readonly') {
      await updateVariable(variableDefinition, value)
      // Else the fetched data is the options for the input
    } else {
      inputData[variableDefinition.name] = value
    }
  }

  function isComplete(variables: Record<string, any>, variableSettings: VariableDefinitions) {
    return variableSettings.every(v => {
      const varType = variableTypes[v.variableType]
      if (varType.inputType === 'readonly') {
        return true
      }
      return variables[v.name] !== null && variables[v.name] !== undefined && !validationErrors[v.name]
    })
  }

  // Debounce emit change events to prevent double updates
  function emitUpdate(fn?: Function) {
    if (!fn) return () => null
    return fn.debounce(100)
  }
  const emitComplete = emitUpdate(onComplete)
  const emitChange = emitUpdate(onChange)

  async function updateVariable(variableDefinition: VariableDefinition, value: any) {
    variables[variableDefinition.name] = value
    const varType = variableTypes[variableDefinition.variableType]

    if (value && varType.validationSchema) {
      const errorKey = varType.validationSchema?.safeParse(value).error?.errors?.[0]?.message
      validationErrors[variableDefinition.name] = errorKey
    }

    if (varType.unique && !validationErrors[variableDefinition.name]) {
      // Update all the variables that depend on this one
      const dependents = variableSettings.value.filter(v => {
        const dependencies = variableTypes[v.variableType].dependencies
        return dependencies?.includes(varType.name)
      })

      await Promise.all(dependents.map(updateVariableData))
    }

    const context = getContext()
    updateContext()

    emitChange(variables, context)
    if (isComplete(variables, variableSettings.value) && onComplete) {
      emitComplete(variables, context)
    }
  }

  async function initDrVariables(_variableSettings: VariableDefinitions, startVariables: Record<string, any> = {}) {
    variableSettings.value = _variableSettings || []
    Object.keys(variables).forEach(k => {
      delete variables[k]
    })
    ;(_variableSettings || []).forEach((v: VariableDefinition) => {
      inputData[v.name] = null
      missingDependencies[v.name] = null
      variables[v.name] = startVariables[v.name] || null
    })
    await Promise.all(_variableSettings.map(updateVariableData))
  }
  return {
    variables,
    inputData,
    context,
    initDrVariables,
    updateVariableData,
    partials,
    runParameters,
    updateVariable,
    missingDependencies,
    validationErrors,
  }
}

export async function getVariableValues(
  variableDefinition: VariableDefinition,
  // settingVariableParameters: VariableDefinitions,
  context: Record<string, any>,
  argument?: string | number,
  onError?: Function,
) {
  const varType = variableTypes[variableDefinition.variableType]
  if (!varType.dataReport) return
  if (varType.dependencies && varType.dependencies.some(d => !context[d])) return
  const drVariables = { ...context }
  if (varType.hasArgument) {
    drVariables[varType.argumentName || 'info'] = argument
  }
  const { data, error } = await dataReportService.run(varType.dataReport, drVariables)
  if (error) {
    onError && onError(error)
  }

  return data?.result
}
