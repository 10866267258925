export function generateUniqueName(name: string, existingNames: string[], counter?: number) {
  const suffix = (counter ?? 0) > 0 ? `(${counter})` : ''
  const uniqueName = `${name}${suffix}`

  if (existingNames.includes(uniqueName)) {
    return generateUniqueName(name, existingNames, (counter ?? 0) + 1)
  }

  return uniqueName
}

export function generateUniqueNameWhenDuplicateWorkflow(
  workflows: { id: number; name: string }[],
  baseWorkflowId: number,
) {
  const baseWorkflow = workflows.find(w => w.id === baseWorkflowId)
  let newWorkflowName = baseWorkflow!.name + ' (copy)'

  const workflowsWithSameName = workflows.some(w => w.name === newWorkflowName)

  if (workflowsWithSameName) {
    newWorkflowName = generateUniqueName(
      newWorkflowName,
      workflows.map(w => w.name),
    )
  }

  // @ts-ignore
  const id = workflows.map('id').max() + 1 || 0

  return { newWorkflowId: id, newWorkflowName, baseWorkflow }
}
