/*
 * this file is used by client and set-up in platform init with .setPolicy(policy)
 * */

import axios from 'axios'
import config from '@100-m/hauru/src/config'

let policies = null

const policy = {
  fetchPolicy: async () => {
    if (!config.graphqlEndpoint) return null
    try {
      const policy = await axios.get(`${config.graphqlEndpoint}/env/policy`)
      policies = policy?.data
      return policy?.data || null
    } catch (e) {
      console.log('Error fetching policy', e)
      return null
    }
  },

  async getPolicy() {
    return policies || (await this.fetchPolicy())
  },

  async checkRole(to) {
    const policy = await this.getPolicy()
    if (!policy)
      return {
        authorized: false,
        isAuthorizedScreen: false,
        userRoleAuthorizedScreens: [],
      }

    if (to.meta.app === 'switch')
      return {
        authorized: true,
        isAuthorizedScreen: true,
        userRoleAuthorizedScreens: await this.findUserRoleAuthorizedScreens(),
      }

    const appPolicy = policy[to.meta.app === 'builder' ? 'client-edition' : to.meta.app]
    const authorized = typeof appPolicy === 'boolean' ? appPolicy : await this.checkPolicy(to.meta.name, to.meta.app)
    const userRoleAuthorizedScreens = await this.findUserRoleAuthorizedScreens()

    return { authorized, isAuthorizedScreen: authorized, userRoleAuthorizedScreens }
  },

  async findUserRoleAuthorizedScreens() {
    const policy = await this.getPolicy()
    let userRoleAuthorizedScreens = []
    if (!policy) return userRoleAuthorizedScreens

    // add screen name when new screen is creating #screen policy
    const screenAvailable = [
      'slash',
      'my-actions',
      'reports',
      'documents',
      'templates',
      'stylesheets',
      'workflows',
      'workflow',
      'data-quality-dashboard',
      'data-reports',
      'image-folders',
      'preview',
    ]

    switch (true) {
      case policy['client-edition'] === true:
        userRoleAuthorizedScreens = screenAvailable
        break
      case typeof policy['client-edition'] === 'object':
        userRoleAuthorizedScreens.push(
          ...screenAvailable
            .filter(screenName => {
              const policyScreenName =
                screenName === 'stylesheets'
                  ? 'template'
                  : screenName === 'data-quality-dashboard'
                    ? 'data-quality'
                    : screenName === 'slash'
                      ? 'workflow'
                      : screenName.replace(/s$/, '')

              return policy['client-edition'][policyScreenName] ? screenName : null
            })
            .filter(e => e),
        )
        break
    }
    return userRoleAuthorizedScreens
  },

  async checkPolicy(pageName = '', app) {
    const policy = await this.getPolicy()
    if (!policy) return false

    const singleNamePageName = pageName.replace(/s$/, '')
    switch (true) {
      case pageName === 'slash':
        return policy['client-edition'].workflow || false
      case pageName === 'data-quality-dashboard':
      case pageName === 'data-quality-report':
      case pageName === 'data-quality-rule':
        return policy['client-edition']['data-quality'] || false
      case pageName === 'data-report-result':
        return policy['client-edition']['data-report'] || false
      case pageName === 'preview':
        return policy['client-edition'].preview || policy['client-edition'].template || false
      case pageName === 'stylesheets':
      case app === 'builder':
        return policy['client-edition'].template || false
      case !pageName:
        return !!policy[app] || false
      default:
        return policy[app][singleNamePageName] || false
    }
  },

  clearPolicy() {
    policies = null
  },
}

export default policy
